<template>
    <div class="userInfo">
        <div class="userContent ">
            <i class="userIcon"></i>
            <span class="userName">{{ userName.slice(0, 6) }}</span>
            <span class="userArrow"></span>
        </div>
        <ul class="userDropdown">
            <li class="userDropdown-value">
                <span class="userDropdown-value__icon setting"></span>
                Account settings
            </li>
            <li class="userDropdown-value">
                <span class="userDropdown-value__icon logOut"></span>
                Log out
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    computed: {
        userName() {
            return window?.ethereum?.selectedAddress ?? 'User'
        },
    }
}
</script>
<style scoped lang="scss">
.userInfo {
    margin-top: 4px;
    height: max-content;
    width: max-content;
    margin-left: auto;
}

.userInfo:hover .userDropdown {
    opacity: 1;
    transition: opacity .3s ease-in-out;
}

.userInfo:hover .userArrow {
    transition: background-image .3s ease-in-out;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='11' viewBox='0 0 20 11' fill='none'%3E%3Cpath d='M19 1L10 10L1 1' stroke='url(%23paint0_linear_1177_9688)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1177_9688' x1='1.18208' y1='6.16638' x2='15.8006' y2='6.16638' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.userContent {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.userIcon {
    width: 46px;
    height: 48px;
    border-radius: 8px;
    background: url(<path-to-image>), lightgray 50% / cover no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0' y='0' width='24' height='24'%3E%3Crect x='0' y='0' width='24' height='24' transform='translate(-1.6681668271030947 -3.6304402725235847) rotate(275.3 12 12)' fill='%2303495E'%3E%3C/rect%3E%3Crect x='0' y='0' width='24' height='24' transform='translate(-10.291636197281422 -1.1475551964934283) rotate(313.9 12 12)' fill='%232356E1'%3E%3C/rect%3E%3Crect x='0' y='0' width='24' height='24' transform='translate(0.39999605687994116 21.449417750204606) rotate(125.1 12 12)' fill='%23FB184D'%3E%3C/rect%3E%3C/svg%3E");
    margin-right: 15px;
}

.userName {
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.userArrow {
    width: 18px;
    height: 10px;
    margin-left: 45px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='12' viewBox='0 0 22 12' fill='none'%3E%3Cpath d='M21 1L11 11L1 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.userDropdown {
    opacity: 0;
    padding: 15px;
    border-radius: 7px;
    background: rgba(13, 11, 23, 0.6)
}

.userDropdown-value {
    display: flex;
    align-items: center;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
}

.userDropdown-value:not(:last-child) {
    margin-bottom: 8px;
}

.userDropdown-value__icon {
    height: 20px;
    width: 20px;
    margin-right: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.setting {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M12.918 10.0013C12.918 11.6121 11.6121 12.918 10.0013 12.918C8.39047 12.918 7.08464 11.6121 7.08464 10.0013C7.08464 8.39047 8.39047 7.08464 10.0013 7.08464C11.6121 7.08464 12.918 8.39047 12.918 10.0013Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5013 3.33464C7.5013 2.41416 8.24749 1.66797 9.16797 1.66797H10.8346C11.7551 1.66797 12.5013 2.41416 12.5013 3.33464V3.96576L12.9476 3.51948C13.5985 2.86861 14.6537 2.86861 15.3046 3.51948L16.4831 4.69799C17.134 5.34887 17.134 6.40414 16.4831 7.05502L16.0368 7.5013H16.668C17.5884 7.5013 18.3346 8.24749 18.3346 9.16797V10.8346C18.3346 11.7551 17.5884 12.5013 16.668 12.5013H16.0368L16.4831 12.9476C17.134 13.5984 17.134 14.6537 16.4831 15.3046L15.3046 16.4831C14.6537 17.134 13.5985 17.134 12.9476 16.4831L12.5013 16.0368V16.668C12.5013 17.5884 11.7551 18.3346 10.8346 18.3346H9.16797C8.24749 18.3346 7.5013 17.5884 7.5013 16.668V16.0368L7.05503 16.4831C6.40416 17.134 5.34888 17.134 4.69801 16.4831L3.51949 15.3046C2.86862 14.6537 2.86862 13.5984 3.51949 12.9476L3.96576 12.5013H3.33464C2.41416 12.5013 1.66797 11.7551 1.66797 10.8346V9.16797C1.66797 8.24749 2.41416 7.5013 3.33464 7.5013H3.96578L3.51949 7.05502C2.86862 6.40415 2.86862 5.34887 3.51949 4.698L4.69801 3.51949C5.34888 2.86861 6.40415 2.86861 7.05503 3.51949L7.5013 3.96576V3.33464ZM14.168 10.0013C14.168 12.3025 12.3025 14.168 10.0013 14.168C7.70012 14.168 5.83464 12.3025 5.83464 10.0013C5.83464 7.70012 7.70012 5.83464 10.0013 5.83464C12.3025 5.83464 14.168 7.70012 14.168 10.0013Z' fill='white'/%3E%3C/svg%3E");
}

.logOut {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10.0629 10.9581C10.8644 10.9581 11.5165 10.3061 11.5165 9.50461V3.55895C11.5165 2.75752 10.8644 2.10547 10.0629 2.10547C9.26145 2.10547 8.60938 2.75752 8.60938 3.55898V9.50464C8.60938 10.3061 9.26145 10.9581 10.0629 10.9581Z' fill='white'/%3E%3Cpath d='M14.9526 6.1207C14.9489 6.11666 14.9451 6.11274 14.9416 6.10926C14.6671 5.83476 14.3021 5.68359 13.9139 5.68359C13.5256 5.68359 13.1606 5.83473 12.8861 6.10926C12.6115 6.38378 12.4603 6.74882 12.4604 7.1371C12.4604 7.5191 12.6068 7.87858 12.873 8.15157C12.8773 8.1561 12.8816 8.1606 12.886 8.16504C13.6409 8.91991 14.0566 9.92355 14.0566 10.9911C14.0566 13.1949 12.2637 14.9878 10.0599 14.9878C7.85616 14.9878 6.06324 13.1949 6.06324 10.9911C6.06324 9.92352 6.47894 8.91988 7.23384 8.16504C7.80043 7.59842 7.8005 6.67632 7.23372 6.10935C6.95916 5.83492 6.59413 5.68375 6.20596 5.68375C5.81774 5.68375 5.45276 5.83492 5.17824 6.10947C3.87432 7.41342 3.15625 9.1471 3.15625 10.9911C3.15625 12.8352 3.87432 14.5688 5.17827 15.8728C6.48221 17.1768 8.21586 17.8949 10.0599 17.8949C11.904 17.8949 13.6376 17.1768 14.9415 15.8728C16.2455 14.5689 16.9636 12.8352 16.9636 10.9911C16.9636 9.15258 16.2495 7.42332 14.9526 6.1207Z' fill='white'/%3E%3C/svg%3E");
}

@media (min-width:1800px) {
    .userInfo {
        margin-top: 36px;
        margin-right: 12px;
    }

    .userIcon {
        width: 55px;
        height: 57px;
    }

    .userName {
        font-size: 18px;
    }

    .userArrow {
        width: 20px;
        height: 10px;
        margin-left: 58px;
    }

    .userDropdown-value {
        font-size: 16px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .userContent {
        margin-bottom: 8px;
    }

    .userInfo {
        margin-top: 13px;
    }

    .userName {
        margin-top: 4px;
        font-size: 15px;
    }

    .userArrow {
        width: 34px;
        height: 10px;
        margin-left: 21px;
        background-position: right;
    }

    .userDropdown {
        padding: 10px 16px;
    }

    .userDropdown-value {
        font-size: 14px;
        margin-right: 3px;
        margin-bottom: 8px;
    }
}

@media (max-width: 680px) {
    .userInfo {
        margin-top: 38px;
    }
    .userContent {
        justify-content: flex-end;
    }

    .userIcon {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }

    .userName {
        font-size: 12px
    }

    .userArrow {
        width: 14px;
        height: 7.765px;
    }

    .userDropdown {
        padding: 13px 10px 3px 13px;
    }

    .userDropdown-value {
        margin-right: 0;
        font-size: 12px;
    }

    .userDropdown-value:not(:last-child) {
        margin-bottom: 6px;
    }

    .userDropdown-value__icon {
        margin-right: 14px;
        width: 14px;
        height: 14px;
    }
}
</style>