<template>
  <div>
    <Main />
    <About />
    <Steps />
    <Accordion />
    <Blockchain />
    <Swiper />
    <Bottom />
    <Footer />
    <PopUp />
  </div>
</template>
<script>
import Main from "@/components/landing/main.vue";
import About from "@/components/landing/about.vue";
import Steps from "@/components/landing/steps.vue";
import Accordion from "@/components/landing/accordion.vue";
import Blockchain from "@/components/landing/blockchain.vue";
import Swiper from "@/components/landing/swiper.vue";
import Bottom from "@/components/landing/bottom.vue";
import Footer from "@/components/landing/footer.vue";
import PopUp from "@/components/landing/loginPopUp.vue";

export default {
  components: {
    Main,
    About,
    Steps,
    Accordion,
    Blockchain,
    Swiper,
    Bottom,
    Footer,
    PopUp,
  },
};
</script>