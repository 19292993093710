// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { reactive, watch } from "vue";
import { WalletDummy } from "./walletDummy";
import { WalletMetamask } from "./walletMetamask";
import { WalletPhantom } from "./walletPhantom";
import { walletProxy } from "../code/walletProxy";
import { Firebase } from "./firebase";
import { Donatrix } from "./donatrix";
import { Account } from "./account";
import { Messages } from "./messages";

const ethereumWallet = reactive(new WalletMetamask());
ethereumWallet.PostConstruct();

const phantomWallet = reactive(new WalletPhantom());
phantomWallet.PostConstruct();

const dummyWallet = reactive(new WalletDummy());

const firebase = reactive(new Firebase());
firebase.PostConstruct();
watch(() => walletProxy.type, firebase.WalletChanged.bind(firebase));

const donatrix = reactive(new Donatrix(firebase));
donatrix.PostConstruct();
watch(() => walletProxy.chainId, donatrix.WalletChanged.bind(donatrix));
watch(() => walletProxy.address, donatrix.WalletChanged.bind(donatrix));
watch(() => firebase.initialized, donatrix.FirebaseInitialized.bind(donatrix));

const account = reactive(new Account(firebase));
watch(() => firebase.account, account.FirebaseAccount.bind(account));
watch(() => donatrix.settingsReady, account.UpdateSettings.bind(account));

const messages = reactive(new Messages(firebase));
messages.Initialize();
watch(() => firebase.account, messages.FirebaseAccount.bind(messages));

export function getWalletEthereum() {
  return ethereumWallet;
}
export function getWalletPhantom() {
  return phantomWallet;
}
export function getWalletDummy() {
  return dummyWallet;
}
export function getFirebase() {
  return firebase;
}
export function getDonatrix() {
  return donatrix;
}
export function getAccount() {
  return account;
}
export function getMessages() {
  return messages;
}
