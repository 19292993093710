<template>
  <div>
    <Dashboard />
    <WalletTestbed />
  </div>
</template>
  
<script>
import Dashboard from '@/components/dashboard/main.vue'
import WalletTestbed from '@/components/testbed/WalletTestbed.vue';

export default {
  components: { WalletTestbed, Dashboard },

  watch: {
    "$firebase.account"(account) {
      console.log(`account changed ${account}`);
    },
  },
  mounted() {
    console.log(`mounted with firebase.account=${this.$firebase.account}`);
  },
}
</script>
  
<style lang="scss">
.layout {
  display: none !important;
}
</style>
  