<template>
  <button @click="click" :class="activity || disabled ? 'disabled' : undefined" class="button">
    <SimpleSpinner v-if="activity" class="icon" />
    <div class="placeholder" />
    <span class="text">
      <slot> </slot>
    </span>
    <div class="placeholder" />
  </button>
</template>

<script>
import SimpleSpinner from "./SimpleSpinner.vue";

export default {
  components: {
    SimpleSpinner,
  },
  props: {
    activity: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    click(event) {
      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  position: relative;
}

.placeholder {
  vertical-align: middle;
  display: inline-block;
  width: 2px;
  //border: 1px solid red;
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.icon {
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
}

//.icon,
.text {
  padding: 0px 12px;
  vertical-align: middle;
  display: inline-block;
}
</style>
