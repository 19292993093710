// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Firebase } from "./firebase";
import { getFirebaseFirestore } from "./firebaseChunks";
import { sharedState } from "./sharedState";
import { getDonatrix } from "./global";
import { LimitsHelper } from "./classes/LimitsHelper";
import { AccountSettings } from "./classes/AccountSettings";
import { MessageGraphCache } from "./classes/MessageGraphCache";

// better not to add more pages here, but handle specific pages with dedicated classes
// see Handle... functions
const ALLOWED_PAGES = Object.freeze(["account", "public"]);

export class Account {
  constructor(firebase) {
    if (!(firebase instanceof Firebase)) throw "InjectError";

    this.limits = new LimitsHelper();
    this.firebase = firebase;
    this.initialized = false;
    this.cancelSubscription = undefined;
    this.generatingToken = false;
    this.sharedState = sharedState;

    this.messageGraph = new MessageGraphCache();

    this.Reset();
  }

  get disableTokenGenerator() {
    return this.firebase.readOnly === true;
  }

  Reset() {
    for (let page of ALLOWED_PAGES) {
      this[page] = {};
    }
    this.sharedState.publicInitialized = undefined;
    this.sharedState.numWallets = 0;
    this.sharedState.accountEvmAddress = undefined;
    this.sharedState.accountSolanaAddress = undefined;

    this.accountSettings = new AccountSettings();
    this.accountSettings.saveDelegate = this.SaveAccountSettings.bind(this);

    this.messageGraph.Reset();

    getDonatrix().Validate();
  }

  FirebaseAccount(account) {
    if (!this.initialized && account) {
      console.log("initializing account");
      this.Init();
      this.UpdateSettings();
    } else if (this.initialized && account === undefined) {
      console.log("deinitializing account");
      this.Cleanup();
    }
  }

  UpdateSettings() {
    const donatrix = getDonatrix();
    if (!this.firebase.account || this.firebase.readOnly || !donatrix.settingsReady) return;

    const settings = donatrix.settings;
    this.limits.UpdateSettings(settings);
  }

  async Init() {
    this.Reset();
    this.initialized = true;
    this.StartListenUserSettings();
  }

  Cleanup() {
    this.initialized = false;
    if (typeof this.cancelSubscription === "function") {
      this.cancelSubscription();
      this.cancelSubscription = undefined;
    }
    this.limits.Reset();
    this.Reset();
  }

  async GenerateAccessToken() {
    if (this.sharedState.accountId === undefined) return;
    if (this.generatingToken) return;
    this.generatingToken = true;
    //console.log("account::generateAccessToken");

    try {
      await this.firebase.CallFunction("generateAccessToken");
      this.generatingToken = false;
    } catch (error) {
      this.generatingToken = false;
      throw error;
    }
  }

  HandleAccountSettings(page, settings) {
    if (page !== "account") return;
    this.accountSettings.fromDBJson(settings || {});
  }

  HandleSharedState(page, data) {
    if (page === "public") {
      this.sharedState.publicInitialized = true;
      this.sharedState.accountEvmAddress = data?.evmAddress;
      this.sharedState.accountSolanaAddress = data?.solanaAddress;

      const fields = ["accountEvmAddress", "accountSolanaAddress"];
      this.sharedState.numWallets = fields.reduce(
        (number, field) => (this.sharedState[field] === undefined ? number : 1 + number),
        0
      );

      if (!this.firebase.readOnly) {
        this.limits.UpdateCurrentLimits(data?.limits || {});
        getDonatrix().Validate();
      }
    }
  }

  HandleGraphCache(page, data) {
    if (page !== "cache") return;
    if (this.firebase.readOnly) return; // skip processing if RO auth
    this.messageGraph.fromDBJson(data);
  }

  async StartListenUserSettings() {
    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const account = this.firebase.account;
    const storageCollection = firestoreModule.collection(firestore, `/user/${account}/storage`);
    this.cancelSubscription = firestoreModule.onSnapshot(storageCollection, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (["added", "modified"].includes(change.type)) {
          if (ALLOWED_PAGES.includes(change.doc.id)) {
            this[change.doc.id] = change.doc.data();
          }
          this.HandleSharedState(change.doc.id, change.doc.data());
          this.HandleAccountSettings(change.doc.id, change.doc.data().settings);
          this.HandleGraphCache(change.doc.id, change.doc.data());
        }
      });
    });
  }

  // this function must match on front- and back- ends to have consistent behavior
  filterNickname(nickname) {
    if (typeof nickname !== "string") throw "empty string";
    //const out = nickname.replace(/[ `~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]/gi, "");
    const out = nickname.replace(/[^a-zA-Z0-9]/gi, "").substring(0, 24);
    if (out.length < 3) throw "too short";
    return out;
  }

  async testNicknameIsUnique(newNickname, filteredCallback) {
    if (newNickname === this.public.nickname) throw "same";

    const filteredValue = this.filterNickname(newNickname);
    if (typeof filteredCallback === "function") filteredCallback(filteredValue);

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const names = firestoreModule.collection(firestore, "/names");
    // prettier-ignore
    const query = firestoreModule.query(names
      , firestoreModule.where("nickname", "==", filteredValue.toLowerCase())
      , firestoreModule.limit(1)
    );
    const snapshot = await firestoreModule.getDocs(query);
    if (snapshot.size > 0) {
      const owner = snapshot.docs[0].data().walletAddress.toLowerCase();
      if (owner === this.firebase.account) return; // all right
      throw "not unique";
    }
  }

  async changeNickname(newNickname) {
    const nickname = this.filterNickname(newNickname);
    await this.firebase.CallFunction("changeNickname", { nickname });
  }

  async SaveAccountSettings(accountSettings) {
    if (!(accountSettings instanceof AccountSettings)) throw `AccountSettings expected got ${accountSettings}`;

    const firestoreModule = await getFirebaseFirestore();
    const settings = accountSettings.toDBJson(firestoreModule.deleteField());
    const num = Object.keys(settings);
    if (num === 0) return; // nothing to do;

    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const accountId = this.firebase.account;
    if (!accountId) return; // nothing to do;

    const accountRef = firestoreModule.doc(firestore, `/user/${accountId}/storage/account`);
    await firestoreModule.setDoc(accountRef, { settings }, { merge: true });
  }
}
