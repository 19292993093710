// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { SettingBool } from "./SettingBool";
import { SettingChoice } from "./SettingChoice";
var AccountSettings = /** @class */ (function () {
    function AccountSettings() {
        var domain = "account";
        this.urlFilterSetting = new SettingBool(domain, "urlFilter", true);
        this.displayCurrency = new SettingChoice(domain, "displayCurrency", ["usd", "eur", "rub"]); // !!! the list must match one supported in backend
        this.saveDelegate = undefined;
    }
    Object.defineProperty(AccountSettings.prototype, "dirty", {
        get: function () {
            return (false
                || this.urlFilterSetting.dirty
                || this.displayCurrency.dirty);
        },
        enumerable: false,
        configurable: true
    });
    AccountSettings.prototype.Save = function () {
        if (this.saveDelegate) {
            return this.saveDelegate(this);
        }
        throw "saveDelegate is not initialized";
    };
    AccountSettings.prototype.Revert = function () {
        this.urlFilterSetting.Revert();
        this.displayCurrency.Revert();
        // ...
    };
    AccountSettings.prototype.fromDBJson = function (data) {
        this.urlFilterSetting.fromDBJson(data);
        this.displayCurrency.fromDBJson(data);
        // ...
    };
    AccountSettings.prototype.toDBJson = function () {
        var data = {};
        this.urlFilterSetting.toDBJson(data);
        this.displayCurrency.toDBJson(data);
        // ...
        return data;
    };
    return AccountSettings;
}());
export { AccountSettings };
