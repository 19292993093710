// Copyright 2023, Alexander Nekrasov, All rights reserved.

function _key(prefix, key) {
  return prefix + "::" + key;
}
export class LocalStorage {
  #mPrefix;

  constructor(prefix) {
    this.#mPrefix = prefix;
  }

  set(key, value) {
    const value_json = JSON.stringify(value);
    localStorage.setItem(_key(this.#mPrefix, key), value_json);
  }

  get(key, defvalue) {
    try {
      const value_json = localStorage.getItem(_key(this.#mPrefix, key)) || null;
      return JSON.parse(value_json) || defvalue;
    } catch (e) {
      return defvalue;
    }
  }

  delete(key) {
    localStorage.removeItem(_key(this.#mPrefix, key));
  }
}
