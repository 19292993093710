// Copyright 2023, Alexander Nekrasov, All rights reserved.
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SettingBase } from "./SettingBase";
var SettingChoice = /** @class */ (function (_super) {
    __extends(SettingChoice, _super);
    function SettingChoice(domain, name, options) {
        var _this = this;
        if (options.length <= 0)
            throw "no valid options";
        _this = _super.call(this, domain, name, "SettingChoice") || this;
        _this.options = options;
        _this.actualValue = options[0];
        _this.pendingValue = undefined;
        return _this;
    }
    Object.defineProperty(SettingChoice.prototype, "dirty", {
        get: function () {
            return this.pendingValue !== undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingChoice.prototype, "value", {
        get: function () {
            return this.dirty ? this.pendingValue : this.actualValue;
        },
        enumerable: false,
        configurable: true
    });
    SettingChoice.prototype.fromDBJson = function (data) {
        this.Reset(data[this.name]);
        this.MarkInitialized();
    };
    SettingChoice.prototype.toDBJson = function (data) {
        if (this.pendingValue !== undefined) {
            data[this.name] = this.pendingValue;
        }
    };
    SettingChoice.prototype.Revert = function () {
        this.pendingValue = undefined;
    };
    SettingChoice.prototype.Reset = function (value) {
        if (value === undefined) {
            this.actualValue = this.options[0];
        }
        else if (this.options.includes(value)) {
            this.actualValue = value;
        }
        else {
            console.error("unexpected value ".concat(value));
        }
        this.pendingValue = undefined;
    };
    SettingChoice.prototype.SetValue = function (value) {
        if (this.options.includes(value)) {
            var isActual = value === this.actualValue;
            this.pendingValue = isActual ? undefined : value;
        }
        else {
            console.error("unexpected value ".concat(value));
        }
    };
    return SettingChoice;
}(SettingBase));
export { SettingChoice };
