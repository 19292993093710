<template>
    <div class="messages">
        <div class="title">
            <span class="title-icon"></span>
            Last Messages
        </div>
        <ul class="messages-item">
            <Message v-for="message in $messages.messages" v-bind:key="message.id" :message="message" />
        </ul>
    </div>
</template>
<script>
import Message from '@/components/dashboard/message.vue';

export default {
    components: { Message },

    watch: {
        "$firebase.account"() {
            this.Start();
        },
        "$donatrix.settingsReady"() {
            this.Start();
        },
    },
    created() {
        this.Start();
    },
    methods: {
        Start() {
            if (!this.$donatrix.settingsReady || !this.$firebase.account) return;
            this.$messages.StartListen();
        },
    },
}
</script>
<style scoped lang="scss">
.messages {
    flex: 1 1 31%;
    margin-top: 34px;
    border-radius: 20px;
    background: linear-gradient(1deg, #0B0923 -25.74%, rgba(88, 76, 114, 0.18) 99.77%);
    padding: 26px 14px 17px 17px;
}

.title {
    margin-top: 2px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--0, linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title-icon {
    width: 21px;
    height: 17px;
    margin-right: 12px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='18' viewBox='0 0 21 18' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.56591 0H15.4377C17.6185 0 19.3864 1.63176 19.3864 3.64463V9.44469C19.3864 11.2736 17.7801 12.7562 15.7986 12.7562C14.9295 12.7562 14.09 13.0474 13.436 13.5756L11.8019 14.8953C11.0575 15.4964 9.94606 15.4964 9.20168 14.8953L7.56761 13.5756C6.91358 13.0474 6.07407 12.7562 5.20501 12.7562C3.22351 12.7562 1.61719 11.2736 1.61719 9.44468V3.64463C1.61719 1.63176 3.38509 0 5.56591 0ZM6.55308 7.74485C7.09829 7.74485 7.54026 7.33691 7.54026 6.83369C7.54026 6.33047 7.09829 5.92253 6.55308 5.92253C6.00788 5.92253 5.56591 6.33047 5.56591 6.83369C5.56591 7.33691 6.00788 7.74485 6.55308 7.74485ZM11.489 6.83369C11.489 7.33691 11.047 7.74485 10.5018 7.74485C9.9566 7.74485 9.51462 7.33691 9.51462 6.83369C9.51462 6.33047 9.9566 5.92253 10.5018 5.92253C11.047 5.92253 11.489 6.33047 11.489 6.83369ZM14.4505 7.74485C14.9957 7.74485 15.4377 7.33691 15.4377 6.83369C15.4377 6.33047 14.9957 5.92253 14.4505 5.92253C13.9053 5.92253 13.4633 6.33047 13.4633 6.83369C13.4633 7.33691 13.9053 7.74485 14.4505 7.74485Z' fill='url(%23paint0_linear_1177_9572)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1177_9572' x1='1.79693' y1='8.80934' x2='16.228' y2='8.80934' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.messages-item {
    margin-top: 24px;
    max-height: 432px;
    padding-left: 7px;
    overflow-y: scroll;
    overflow-x: hidden;

}

.messages-item::-webkit-scrollbar {
    width: 9px;
    background-color: transparent;
}

.messages-item::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.20);
}

@media (min-width:1800px) {
    .messages {
        padding: 26px 14px 31px 17px;
    }

    .title {
        font-size: 18px;
        margin-left: 9px;
    }

    .title-icon {
        width: 26px;
        height: 26px;
    }

    .messages-item {
        margin-top: 52px;
        max-height: 435px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .messages {
        flex: 1 1 100%;
        margin-top: 17px;
        padding: 21px 18px 24px 17px;
    }

    .title {
        margin-top: 0;
        margin-left: 11px;
        font-size: 14px;
    }

    .title-icon {
        margin-top: 3px;
        width: 20px;
        height: 18px;
    }

    .messages-item {
        margin-top: 17px;
        max-height: 249px;
        padding-left: 15px;

    }
}

@media (max-width: 680px) {
    .messages {
        margin-top: 24px;
        padding: 21px 4px 20px 0px;
    }

    .title {
        margin-left: 13px;
    }

    .title-icon {
        margin-right: 7px;
    }

    .messages-item {
        max-height: 285px;
    }
}
</style>