// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { sharedState } from "./sharedState";
import { WalletNotImplementedError } from "./walletError";
import { LocalStorage } from "./classes/LocalStorage";

export const WalletType = Object.freeze({
  Dummy: "Dummy",
  Ethereum: "Ethereum",
  Solana: "Solana",
});

const WalletAction = Object.freeze({
  Download: "Download",
  Connect: "Connect",
  SignIn: "SignIn",
  Activate: "Activate",
  Attach: "Attach",
  Detach: "Detach",
});

function notImplemented() {
  throw new WalletNotImplementedError();
}

const localStorage = new LocalStorage("wallet");

export class WalletBase {
  constructor(name, type) {
    this.uuid = window.crypto.randomUUID();
    console.assert(Object.values(WalletType).includes(type));
    this.type = type;
    this.name = name;
    this.detectedValue = false; // detected as browser extension/found provider etc
    this.sharedState = sharedState;

    // common fields
    this.chainId = undefined;
    this.addressValue = undefined;
    this.isPendingConnect = false;
    this.isPendingSign = false;
    this.isPendingTx = false;
    this.isPendingNetwork = undefined;

    // stuff to override
    this.zeroAddress = undefined;
    this.redirectToHomePageImpl = notImplemented;
    this.connectImpl = notImplemented;
    this.personalSignImpl = notImplemented;
    this.sendTxImpl = notImplemented;
    this.sendApproveTxImpl = notImplemented;
    this.sendDonateTxImpl = notImplemented;
    this.changeNetworkImpl = notImplemented;
    this.getAttached = () => false;
    this.getAttachable = () => false;
    this.getDetachable = () => false;
    this.getBalanceImpl = () => Promise.resolve(0n);
    this.getTokenBalanceImpl = () => Promise.resolve(0n);
    this.getTokenAllowanceImpl = () => Promise.resolve(0n);
  }

  get destinationAddressField() {
    return undefined; // evmAddress, solanaAddress etc
  }

  get isPending() {
    return (
      this.isPendingConnect ||
      this.isPendingSign ||
      this.isPendingTx ||
      !!this.isPendingNetwork ||
      this.sharedState.signingInWithUuid === this.uuid
    );
  }

  get isDisabled() {
    return this.sharedState.signingInWithUuid !== undefined && this.sharedState.signingInWithUuid !== this.uuid;
  }

  get detected() {
    return this.detectedValue;
  }

  set detected(value) {
    this.detectedValue = value;

    const lastUsed = localStorage.get("lastUsed");
    if (this.name === lastUsed) {
      this.Activate({ safeAsLastUsed: false });
    }
  }

  get address() {
    return this.addressValue;
  }
  set address(value) {
    this.addressValue = value;
  }

  get isConnected() {
    return this.address !== undefined;
  }

  get isAttached() {
    return this.getAttached();
  }

  get isActive() {
    return this.uuid === this.sharedState.activeWalletUuid;
    //return this.isConnected && this.uuid === this.sharedState.activeWalletUuid;
  }

  get isUsableForSignIn() {
    return this.sharedState.accountId === undefined && this.isConnected === true;
  }

  get isAttachable() {
    return this.getAttachable();
  }

  get isDetachable() {
    return this.sharedState.numWallets > 1 && this.getDetachable();
  }

  get defaultAction() {
    if (!this.detected) return WalletAction.Download;
    if (!this.isConnected) return WalletAction.Connect;
    if (this.isUsableForSignIn) return WalletAction.SignIn;
    if (this.isAttachable) return WalletAction.Attach;
    if (!this.isActive) return WalletAction.Activate;
    return undefined;
  }

  Action() {
    if (this.isDisabled) return;

    switch (this.defaultAction) {
      case WalletAction.Download:
        return this.redirectToHomePageImpl();
      case WalletAction.Connect:
        return this.Connect();
      case WalletAction.Activate:
        return this.Activate();
      case WalletAction.SignIn:
        return this.sharedState.signInDelegate(this);
      case WalletAction.Attach:
        return this.sharedState.attachWalletDelegate(this);
      // case WalletAction.Detach:
      //   return this.Detach();
    }
  }

  Detach() {
    return this.sharedState.detachWalletDelegate(this);
  }

  Deactivate() {
    if (!this.detected) return;

    this.sharedState.activeWalletUuid = undefined;
    this.sharedState.activeWallet = undefined;
    localStorage.delete("lastUsed");
  }

  Activate(params = { safeAsLastUsed: true }) {
    if (!this.detected) return;

    this.sharedState.activeWalletUuid = this.uuid;
    this.sharedState.activeWallet = this;
    if (params?.safeAsLastUsed === true) {
      localStorage.set("lastUsed", this.name);
    }
  }

  Connect() {
    return this.connectImpl();
  }

  PersonalSign(challenge) {
    return this.personalSignImpl(challenge);
  }

  SendTxImpl(...args) {
    return this.sendTxImpl(...args);
  }

  SendApproveTx(tokenAddress, spenderAddress, rawAmount) {
    return this.sendApproveTxImpl(tokenAddress, spenderAddress, rawAmount);
  }

  SendDonateTx(
    recipientAddress,
    tokenAddress,
    rawAmount,
    feeUnits,
    params = { splitterAddress: undefined, feeAddress: undefined },
    txHashCallback
  ) {
    return this.sendDonateTxImpl(recipientAddress, tokenAddress, rawAmount, feeUnits, params, txHashCallback);
  }

  ChangeNetwork(chainId) {
    return this.changeNetworkImpl(chainId);
  }

  GetBalance() {
    return this.getBalanceImpl();
  }

  GetTokenBalance(tokenAddress) {
    return this.getTokenBalanceImpl(tokenAddress);
  }

  GetTokenAllowance(tokenAddress, allowanceFor) {
    return this.getTokenAllowanceImpl(tokenAddress, allowanceFor);
  }

  static GetChainType(type) {
    switch (type) {
      case WalletType.Dummy:
        return "dummy";
      case WalletType.Ethereum:
        return "evm";
      case WalletType.Solana:
        return "solana";
    }
    throw "this is not expected";
  }
}
