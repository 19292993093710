// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { GetEthers } from "./ethersChunk";

// prettier-ignore
const donatrixABI = [
  "function GetFeePercent() external view returns (uint256)",
  "function DonateNative(address donateFor, uint256 feeUnits)",
  "function DonateToken(address donateFor, uint256 value, address erc20Contract, uint256 feeUnits)",
  "event DonationEvent(address indexed donateTo, uint256 value, address indexed currencyContract)",
];

export async function createDonateTxData(donateFor, feeUnits) {
  const ethers = await GetEthers();
  if (!ethers.utils.isAddress(donateFor)) throw "invalid address";
  if (typeof feeUnits !== "bigint" || feeUnits < 0n || feeUnits > 10000n) throw "invalid feeUnits";

  const iface = new ethers.utils.Interface(donatrixABI);
  const txData = iface.encodeFunctionData("DonateNative", [donateFor, feeUnits]);
  return txData;
}

export async function createDonateTokenTxData(donateFor, value, erc20Contract, feeUnits) {
  const ethers = await GetEthers();
  if (!ethers.utils.isAddress(donateFor)) throw "invalid address";
  if (!ethers.utils.isAddress(erc20Contract)) throw "invalid address";
  if (!value) throw "invalid value";
  if (typeof feeUnits !== "bigint" || feeUnits < 0n || feeUnits > 10000n) throw "invalid feeUnits";

  const iface = new ethers.utils.Interface(donatrixABI);
  const txData = iface.encodeFunctionData("DonateToken", [donateFor, value, erc20Contract, feeUnits]);
  return txData;
}

// export async function writeExample(contractAddress, ethereumProvider, value) {
//   const ethers = await GetEthers();
//   const provider = new ethers.providers.Web3Provider(ethereumProvider);
//   const signer = provider.getSigner();
//   const contract = new ethers.Contract(contractAddress, donatrixABI, signer);
//   const serviceFee = await contract.GetFeePercent();
//   return Number(serviceFee) / 100;
// }
