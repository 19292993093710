<template>
  <div class="icontext">
    <img v-if="icon" class="icon" :src="icon || ''" :alt="name+' icon'"/>
    <div v-else class="icon capital">{{name[0]}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "" },
    name: { type: String, default: "" },
  },
}
</script>

<style scoped lang="scss">
$size: 32px;
$border: 2px;
$color: #30f;

.icon {
  margin-right: 8px;
  color: $color;
}

.icontext {
  display: flex;
  align-items: center;

  img {
    width: $size;
    height: $size;
  }
}

.capital {
  box-sizing: border-box;
  border: $border dashed $color;
  border-radius: 50%;
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  line-height: ($size - 2*$border);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

</style>
