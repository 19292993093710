// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { getDonatrix } from "../code/global";

export default {
  install(app /*, options*/) {
    if (app.config?.globalProperties) {
      //console.log("Vue3");
      app.config.globalProperties.$donatrix = getDonatrix();
    } else if (app.prototype) {
      //console.log("Vue2");
      app.prototype.$donatrix = getDonatrix();
    }
  },
};
