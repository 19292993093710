// Copyright 2023, Alexander Nekrasov, All rights reserved.
import { MessageSummaryItem } from "./MessageSummaryItem";
import { getInterval } from "../intervalLib";
var MessageGraphCache = /** @class */ (function () {
    function MessageGraphCache() {
        this.rawData = {};
        this.period = "week";
        this.timezoneOffset = 0;
        this.items = [];
        this.loading = true;
    }
    MessageGraphCache.GetCacheLookupPeriod = function (period) {
        // we never have cache for "week", so using "month" for cache lookup
        return period === "week" ? "month" : period;
    };
    MessageGraphCache.prototype.Reset = function () {
        // intentionally do not reset period and offset
        this.rawData = {};
        this.items = [];
        this.loading = true;
    };
    MessageGraphCache.prototype.RebuildForDay = function () {
        this.period = "day";
        this.Rebuild();
    };
    MessageGraphCache.prototype.RebuildForWeek = function () {
        this.period = "week";
        this.Rebuild();
    };
    MessageGraphCache.prototype.RebuildForMonth = function () {
        this.period = "month";
        this.Rebuild();
    };
    MessageGraphCache.prototype.RebuildForYear = function () {
        this.period = "year";
        this.Rebuild();
    };
    MessageGraphCache.prototype.Rebuild = function () {
        var _a;
        var cachePeriod = MessageGraphCache.GetCacheLookupPeriod(this.period);
        this.timezoneOffset = ((_a = this.rawData) === null || _a === void 0 ? void 0 : _a.timezoneOffset) || 0;
        var interval = getInterval(this.period, this.timezoneOffset);
        this.items = [];
        for (var i = 0; i < interval.dates.length - 1; ++i) {
            var startDate = interval.dates[i];
            var endDate = interval.dates[i + 1];
            var cacheName = this.period === "day" ? startDate.toISOString() : "".concat(startDate.toISOString()).concat(this.timezoneOffset >= 0 ? "+" : "").concat(this.timezoneOffset);
            var cacheRecord = (this.rawData[cachePeriod] || {})[cacheName];
            var item = new MessageSummaryItem(cacheName, startDate, endDate, cacheRecord);
            this.items.push(item);
        }
    };
    MessageGraphCache.prototype.fromDBJson = function (data) {
        this.rawData = JSON.parse(JSON.stringify(data || {}));
        this.loading = false;
        this.Rebuild();
    };
    return MessageGraphCache;
}());
export { MessageGraphCache };
