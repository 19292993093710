<template>
    <div class="burger-menu" :class="{ 'open': burgerVisible }">
        <div class="container">
            <div class="burger-menu__top">
                <div class="burger-menu__language">
                    <span class="burger-language__icon"></span>
                    <div class="dropdown">
                        <div class="burger-language__choose">
                            {{ $i18n.locale.toUpperCase() }}
                        </div>
                        <div class="dropdown-content">
                            <switchLocale />
                        </div>
                    </div>
                    <span class="burger-language__dot"></span>
                </div>
                <button class="burger-menu__button" @click="openBurger">
                </button>
            </div>

            <span class="burger-logo">
                <img src="images/heroLogo.gif" width="130px" height="96px" alt="logo"
                    class="hero-logo__icon burger-logo__icon">
                <img src="images/Donatrix.png" class="donatrix burger-donatrix" alt="donatrix">
            </span>

            <ul class="burger-nav__list">
                <li class="burger-nav__item">
                    <span class="burger-nav__item-icon"></span>
                    <a href="#accordion-anchor" class="burger-nav__item-link">
                        {{ $t('header.Advantages') }}
                    </a>
                </li>
                <li class="burger-nav__item">
                    <span class="burger-nav__item-icon"></span>
                    <a href="#blockchain" class="burger-nav__item-link">
                        {{ $t('header.Networks') }}
                    </a>
                </li>
                <li class="burger-nav__item">
                    <span class="burger-nav__item-icon"></span>
                    <a href="#swiper" class="burger-nav__item-link">
                        {{ $t('header.Currency') }}
                    </a>
                </li>
            </ul>

            <button class="burger-button btn" @click="openLogIn">
                {{ userAreLoged == '' ? login : userAreLoged.slice(0, 6) }}
            </button>

            <MediaLinks />

            <div class="burger-text">
                © Donatrix //2023
            </div>
        </div>
    </div>
</template>
<script>
import MediaLinks from '@/components/landing/mediaLinks.vue';
import switchLocale from '@/components/landing/localeSwitcher.vue';

export default {
    props: {
        burgerVisible: {
            type: Boolean,
            default: false
        },
        openBurger: Function,
        userAreLoged: String,
        openLogIn: Function,
    },

    computed: {
        login() {
            return this.$t('header.logIn')
        }
    },

    components: { MediaLinks, switchLocale }
}
</script>
<style scoped lang="scss">
.burger-menu {
    display: none;
}

@media (max-width: 680px) {
    .burger-menu {
        display: flex;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #07041B;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .container {
        width: 100%;
    }

    .burger-menu__top {
        padding-top: 67px;
        display: flex;
        justify-content: space-between;
    }

    .burger-menu__language {
        display: flex;
        align-items: center;
        background: #07041B;
        margin-right: 0;
    }

    .burger-language__icon {
        width: 23px;
        height: 14px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("../../../public/images/languageIcon.png");
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        top: 14px;
        left: -5px;
        background-color: transparent;
        width: -moz-max-content;
        width: max-content;
        padding: 6px 7px;
        border-radius: 5px;
        background: rgba(217, 217, 217, 0.12);
    }

    .dropdown-content a {
        color: #fff;
        padding: 2px 0px;
        text-decoration: none;
        display: block;
        font-family: Gilroy-Regular;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .dropdown-content a:hover {
        color: #9747ff;
        transition: color 0.3s ease-in;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .burger-language__choose {
        width: 20px;
        color: #FFF;
        font-family: Gilroy-Regular;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-top: 0;
        width: 20px;
    }

    .burger-language__dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #FFF;
    }

    .burger-menu__language:hover .burger-language__dot {
        background-color: #9747FF;
        transition: background-color 0.3s ease-in;
    }

    .burger-menu__button {
        margin-top: -2px;
        margin-right: -2px;
        width: 20px;
        height: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='20' viewBox='0 0 24 20' fill='none'%3E%3Cg filter='url(%23filter0_f_742_160)'%3E%3Cpath d='M5 15H13M5 10H19M11 5H19' stroke='url(%23paint0_linear_742_160)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cpath d='M5 15H13M5 10H19M11 5H19' stroke='url(%23paint1_linear_742_160)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3Cfilter id='filter0_f_742_160' x='0' y='0' width='24' height='20' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='2' result='effect1_foregroundBlur_742_160'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_742_160' x1='5.14162' y1='10.7404' x2='16.5116' y2='10.7404' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_742_160' x1='5.14162' y1='10.7404' x2='16.5116' y2='10.7404' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    }

    .burger-logo {
        display: flex;
        position: relative;
        top: 63px;
        left: 24px;
        width: -moz-max-content;
        width: max-content;
        height: -moz-max-content;
        height: max-content;
    }

    .burger-logo__icon {
        width: 190px;
        height: 111px;
    }

    .burger-donatrix {
        position: absolute;
        width: 74px;
        right: 0;
        top: 48%;
    }

    .burger-nav__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        margin-top: 112px;
        max-width: -moz-max-content;
        max-width: max-content;
    }

    .burger-nav__item {
        display: flex;
        position: relative;
        margin-right: 0;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }

    .burger-nav__item-icon {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath d='M6.73867 4.12721L4.50687 1.87975C3.72517 1.09256 2.45339 1.08792 1.66597 1.86938C0.87413 2.65522 0.874128 3.93569 1.66596 4.72152L3.98608 7.02407C5.46801 8.49477 4.42652 11.0227 2.33869 11.0227H1.98864C0.890343 11.0227 0 11.9131 0 13.0114C0 14.1097 0.890342 15 1.98864 15H11.7528C12.5148 15 13.2483 14.71 13.8044 14.1888L14.1122 13.9003C14.6786 13.3694 15 12.6277 15 11.8513C15 11.1028 14.7012 10.3853 14.1699 9.858L13.2848 8.97957C12.09 7.79383 12.1044 5.85732 13.3168 4.68953L14.3407 3.70324C14.762 3.29745 15 2.7377 15 2.15277V2.06107C15 0.922773 14.0772 0 12.9389 0C11.8006 0 10.8779 0.922773 10.8779 2.06107V2.42118C10.8779 4.58231 8.26147 5.66069 6.73867 4.12721Z' fill='white'/%3E%3C/svg%3E");

    }

    .burger-nav__item-link {
        color: #FFF;
        font-family: Gilroy-Medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    .burger-nav__item:not(:last-child) {
        margin-bottom: 40px;
    }

    .burger-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-top: 55px;
        margin-bottom: 143px;
        min-width: 101px;
        max-width: -moz-max-content;
        max-width: max-content;
        height: 34px;
        font-size: 15px;
    }

    .burger-text {
        margin-top: 24.34px;
        color: #FFF;
        font-family: Gilroy-Regular;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .open {
        z-index: 2;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
}
</style>