<template>
  <div :class="classes">
    <SettingBoolWidget v-if="setting?.type === 'SettingBool'" :setting="setting" :readOnly="readOnly" />
    <SettingChoiceWidget v-else-if="setting?.type === 'SettingChoice'" :setting="setting" :readOnly="readOnly" />
  </div>
</template>

<script>
import { SettingBase } from "../../code/classes/SettingBase";
import SettingBoolWidget from "./SettingBoolWidget";
import SettingChoiceWidget from "./SettingChoiceWidget";

export default {
  components: {
    SettingBoolWidget,
    SettingChoiceWidget,
  },
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingBase },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.setting?.uninitialized) classes.push("uninitialized");
      return classes.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.uninitialized {
  opacity: 0.5;
  pointer-events: none;
}
</style>
