<template>
    <section id="main">
        <div class="container">
            <div class="main-content">
                <div class="main-content__left" v-if="menuOpened">
                    <Menu />
                </div>
                <div class="main-content__right">
                    <div class="main-content__right-top">
                        <div class="burger" @click="menuOpen"></div>

                        <img class="logo" src="../../../public/images/dashboardLogo.svg" alt="Logo">

                        <UserInfo />
                    </div>

                    <div class="main-content__right-main">
                        <Daynamic />

                        <Messages />
                    </div>
                </div>
            </div>
            <div class="bottom"></div>
        </div>
    </section>
</template>
<script>
import Menu from '@/components/dashboard/menu.vue';
import UserInfo from '@/components/dashboard/userInfo.vue';
import Daynamic from '@/components/dashboard/daynamic.vue';
import Messages from '@/components/dashboard/messages.vue';

export default {
    components: { Menu, UserInfo, Daynamic, Messages },

    data: () => ({
        menuOpened: false,
    }),

    created() {
        if (window.innerWidth > 1289) {
            this.menuOpened = true
        }
    },


    watch: {
        menuOpened: function (value) {
            if (value) {
                if (window.innerWidth < 1289) {
                    document.addEventListener('click', event => {
                        if ((!document.querySelector('.burger')?.contains(event.target))
                            && (!document.querySelector('.main-content__left')?.contains(event.target))) {
                            console.log('test')
                            this.menuOpened = false
                        }
                    });
                }
            }
        },
    },

    methods: {
        menuOpen() {
            if (!this.menuOpened) {
                this.menuOpened = true
            } else {
                this.menuOpened = false
            }

        }
    },
}
</script>
<style scoped lang="scss">
#main {
    display: flex;
    padding-top: 24px;
    background: url('../../../public/images/dashboardBack.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-content {
    display: flex;
}

.main-content__left {
    flex: 1 1 20%;
}

.main-content__right {
    flex: 1 1 83%;
}

.main-content__right-top {
    display: flex;
}

.burger {
    display: none;
}

.logo {
    display: none;
}

.main-content__right-main {
    margin-top: 10px;
    display: flex;
}

.bottom {
    height: 291px;
}

@media (min-width:1800px) {
    #main {
        padding-top: 20px;
    }

    .container {
        max-width: 1783px;
        width: 1783px;
    }

    .main-content__right-main {
        margin-top: -19px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    #main {
        padding-top: 28px;
    }

    .main-content {
        position: relative;
    }

    .main-content__left {
        display: flex;
        position: absolute;
        flex: none;
        top: 9%;
    }

    .main-content__right {
        flex: 1 1 100%;
    }

    .burger {
        display: flex;
        margin-top: 32px;
        margin-left: 3px;
        width: 17px;
        height: 13px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='16' viewBox='0 0 21 16' fill='none'%3E%3Cpath d='M2 14H11.7143M2 8H19M9.28571 2H19' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }

    .burger:hover,
    .burger-active {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='16' viewBox='0 0 21 16' fill='none'%3E%3Cpath d='M2 14H11.7143M2 8H19M9.28571 2H19' stroke='url(%23paint0_linear_1177_10580)' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1177_10580' x1='2.17197' y1='8.8885' x2='15.9783' y2='8.8885' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    }

    .logo {
        display: flex;
        margin-left: 38px;
        width: 106.138px;
        height: 74.848px;
    }

    .main-content__right-main {
        flex-direction: column;
        margin-top: -15px;
    }

    .bottom {
        height: 30px;
    }
}

@media (max-width: 680px) {
    .main-content__left {
        top: 10%;
    }

    .main-content__right-top {
        justify-content: space-between;
    }

    .burger {
        width: 16px;
        height: 14px;
        margin-top: 43px;
        margin-left: -1px;
    }

    .logo {
        width: 81px;
        height: 53.182px;
        margin-top: 24px;
        margin-left: 46px;
    }

    .main-content__right-main {
        margin-top: 10px;
    }

}
</style>