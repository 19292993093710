var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu"},[_c('div',{staticClass:"logo"}),_c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"separator"}),_c('li',{staticClass:"menu-nav__item",class:{'active' : _vm.currentPage.match('dashboard')}},[_c('span',{staticClass:"menu-nav__item-icon dashboard"}),_vm._v(" Dashboard ")]),_vm._m(0),_vm._m(1),_c('li',{staticClass:"separator"}),_vm._m(2),_vm._m(3),_c('li',{staticClass:"separator"}),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item"},[_c('span',{staticClass:"menu-nav__item-icon control"}),_vm._v(" Control Panel ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item"},[_c('span',{staticClass:"menu-nav__item-icon lastMes"}),_vm._v(" Last Messages ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item"},[_c('span',{staticClass:"menu-nav__item-icon donationsPage"}),_vm._v(" Donations Page ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item"},[_c('span',{staticClass:"menu-nav__item-icon donationsSet"}),_vm._v(" Donations settings ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item"},[_c('span',{staticClass:"menu-nav__item-icon widgets"}),_vm._v(" WIDGETS ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-nav__item last"},[_c('span',{staticClass:"menu-nav__item-icon"}),_vm._v(" Alerts ")])
}]

export { render, staticRenderFns }