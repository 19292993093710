<template>
    <footer id="footer">
        <div class="container">
            <div class="footer-content">
                <div class="footer-left">
                    <a href="#" class="footer-logo"></a>
                    <ul class="footer-text">
                        <li class="footer-text__item">
                            © Donatrix //2023
                        </li>
                        <li class="footer-text__item">
                            <a href="#" class="footer-text__item-link">
                                {{ $t('footer.Privacy') }}
                            </a>
                        </li>
                        <li class="footer-text__item">
                            <a href="#" class="footer-text__item-link">
                                {{ $t('footer.Personal') }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="footer-right">
                    <button class="footer-button btn">
                        {{ $t('footer.button') }}
                        <img src="../../../public/images/footerBtnTeleg.svg" width="15.953px" height="13px" alt="telegram"
                            class="svg">
                    </button>

                    <MediaLinks />
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import MediaLinks from '@/components/landing/mediaLinks.vue'

export default {
    components: { MediaLinks }
}
</script>
<style scoped lang="scss">
#footer {
    background: #10061E;
    padding-top: 8px;
    padding-bottom: 38px;
}

#footer .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#footer .footer-content .footer-logo {
    margin-bottom: 14px;
    display: block;
    width: 146px;
    height: 105px;
    background-image: url("../../../public/images/Logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#footer .footer-content .footer-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#footer .footer-content .footer-text__item {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#footer .footer-content .footer-text__item-link {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#footer .footer-content .footer-text__item-link:hover {
    color: #9747FF;
    transition: color 0.3s ease-in;
}

#footer .footer-content .footer-text__item:not(:last-child) {
    margin-right: 33px;
}

#footer .footer-content .footer-right {
    padding-top: 20px;
}

#footer .footer-content .footer-right .footer-button {
    width: 325px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 31px;
}

#footer .footer-content .footer-right .footer-button .svg {
    margin-left: 10px;
}

@media (min-width: 1800px) {
    #footer {
        margin-top: -1px;
        padding-top: 9px;
        padding-bottom: 48px;
    }

    #footer .footer-content .footer-left {
        margin-left: 14px;
    }

    #footer .footer-content .footer-left .footer-logo {
        width: 194.667px;
        height: 140px;
        margin-bottom: 24px;
    }

    #footer .footer-content .footer-left .footer-text__item {
        font-size: 12px;
    }

    #footer .footer-content .footer-left .footer-text__item:not(:last-child) {
        margin-right: 36px;
    }

    #footer .footer-content .footer-right {
        padding-top: 61px;
    }

    #footer .footer-content .footer-right .footer-button {
        margin-bottom: 27px;
        width: 429px;
        height: 53px;
        font-size: 20px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    #footer .footer-content .footer-left {
        padding-left: 4px;
    }

    #footer .footer-content .footer-left .footer-logo {
        width: 114px;
        height: 81px;
        margin-bottom: 24px;
    }

    #footer .footer-content .footer-left .footer-text__item {
        font-size: 8px;
    }

    #footer .footer-content .footer-left .footer-text__item-link {
        font-size: 8px;
    }

    #footer .footer-content .footer-right {
        padding-top: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #footer .footer-content .footer-right .footer-button {
        min-width: 191px;
        max-width: -moz-max-content;
        max-width: max-content;
        height: 38px;
        font-size: 12px;
    }
}

@media (max-width: 680px) {
    #footer {
        position: relative;
    }

    #footer .footer-content {
        flex-direction: column-reverse;
    }

    #footer .footer-content .footer-left {
        padding-left: 0px;
        padding-top: 25px;
        margin-left: -15px;
    }

    #footer .footer-content .footer-left .footer-logo {
        margin: 0;
        width: 91px !important;
        position: absolute;
        top: -108px;
        left: 37%;
    }

    #footer .footer-content .footer-left .footer-text__item:not(:last-child) {
        margin-right: 26px;
    }

    #footer .footer-content .footer-right {
        padding-top: 13px;
    }

    #footer .footer-content .footer-right .footer-media__link:not(:last-child) {
        margin-right: 16.51px;
    }
}</style>