// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { WalletBase, WalletType } from "./walletBase";

export class WalletDummy extends WalletBase {
  constructor() {
    super("Dummy", WalletType.Dummy);
    this.detected = true;
    this.address = undefined;

    this.connectImpl = this.Connect.bind(this);
  }

  Connect() {
    this.chainId = "devnet";
    this.address = "some";
    this.Activate();
  }
}
