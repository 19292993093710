export const aboutListItems = [
    {   
        id: 1,
        click: 'openSafe',
        className: 'Safe',
        number: '01',
        text: 'Safe and secure',
    },
    {   
        id: 2,
        click: 'openAnonymous',
        className: 'Anonymous',
        number: '02',
        text: 'Anonymous and confidential',
    },
    {   
        id: 3,
        click: 'openAlerts',
        className: 'Alerts',
        number: '03',
        text: 'Alerts and widgets',
    },
    {   
        id: 4,
        click: 'openWeb',
        className: 'Web',
        number: '04',
        text: 'Web 3 technologies',
    },
]