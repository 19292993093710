<template>
  <div class="testbed-message-root">
    <MessageWidget
      v-for="message in $messages.messages"
      v-bind:key="message.id"
      :message="message"
    />
  </div>
</template>

<script>
import MessageWidget from "./MessageWidget.vue";

export default {
  components: {
    MessageWidget,
  },
  watch: {
    "$firebase.account"() {
      this.Start();
    },
    "$donatrix.settingsReady"() {
      this.Start();
    },
  },
  created() {
    this.Start();
  },
  methods: {
    Start() {
      if (!this.$donatrix.settingsReady || !this.$firebase.account) return;
      this.$messages.StartListen();
    },
  },
};
</script>

<style scoped lang="scss">
.testbed-message-root {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
</style>
