// Copyright 2023, Alexander Nekrasov, All rights reserved.

export class BlockchainSettings {
  constructor() {
    this.chains = [];
    this.solanaChains = [];
    this.initialized = false;
    this.renameDelaySeconds = 60;
    this.serviceFeePercent = undefined;
    this.messageLimit = 1000;
    this.tickerOrder = [];
    this.limits = {};
  }

  fromJson(data) {
    this.chains = JSON.parse(JSON.stringify(data)).chains || [];
    this.solanaChains = JSON.parse(JSON.stringify(data)).solanaChains || [];
    //console.log(this.chains);
    this.initialized = true;
    this.renameDelaySeconds = data?.renameDelaySeconds || 60;
    this.serviceFeePercent = data?.serviceFeePercent || 10;
    this.messageLimit = data?.messageLimit || 1000;
    this.tickerOrder = data?.tickerOrder || [];
    this.limits = data?.limits || {};
  }

  findToken(address) {
    address = address.toLowerCase();
    for (let chain of this.chains) {
      for (let token of chain.supportedTokens) {
        if (token.address.toLowerCase() === address) return token;
      }
    }
    for (let chain of this.solanaChains) {
      for (let token of chain.supportedTokens) {
        if (token.address.toLowerCase() === address) return token;
      }
    }
    return undefined;
  }
}
