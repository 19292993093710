// Copyright 2023, Alexander Nekrasov, All rights reserved.

export function formatFiatCurrency(value) {
  if (isNaN(value)) return "0";

  if (value < 0.01) return "<0.01";
  else if (value < 1) return value.toFixed(2);
  else if (value < 10) return value.toFixed(1);
  else return value.toFixed(0);
}

export function formatAddress(value) {
  if (typeof value !== "string") return "";
  if (value.length <= 10) return value;
  //return value.slice(0, 6) + ".." + value.slice(-4);
  //return value.slice(0, 8);
  return value.slice(-6);
}

export function formatAccountId(accountId) {
  if (typeof accountId !== "string") return "";
  if (accountId.length <= 8) return accountId;
  return accountId.slice(0, 4) + ".." + accountId.slice(-3);
}

function createUrlFilterRegex() {
  const protocol = /\w+:\/\//;
  const start = "\u{100}";
  const end = "\u{10FFF0}";

  // prettier-ignore
  // eslint-disable-next-line no-misleading-character-class
  const atLeastOneLetter = new RegExp(`[a-zA-Z${start}-${end}]+[0-9]|[0-9]+[a-zA-Z${start}-${end}]|[a-zA-Z${start}-${end}]`);
  const hostWord = new RegExp(`(?=${atLeastOneLetter.source})[\\w${start}-${end}\\-]+`);
  // eslint-disable-next-line no-misleading-character-class
  const pathWord = new RegExp(`[\\:\\%\\w${start}-${end}\\-]+`);

  const fileExt = new RegExp(`\\.${pathWord.source}`);
  const filePath = new RegExp(`(/?(${pathWord.source})?)+(${fileExt.source})?`);

  const urlParamPair = new RegExp(`${pathWord.source}\\=${pathWord.source}`);
  const urlParams = new RegExp(`\\?${urlParamPair.source}(\\&${urlParamPair.source})*`);
  const urlLocation = new RegExp(`\\#(${filePath.source})+`);
  const fullPath = new RegExp(`${filePath.source}(${urlParams.source})?(${urlLocation.source})?`);

  const username = /\w+(:\.+)?@/;
  const port = /:[0-9]+/;
  // prettier-ignore
  const host1 = new RegExp(`(${protocol.source})?(${username.source})?${hostWord.source}(\\.${hostWord.source})+(${port.source})?`);
  const host2 = new RegExp(`(${protocol.source})(${username.source})?(\\.?${hostWord.source})+(${port.source})?`);
  const url = new RegExp(`(${host1.source}|${host2.source})(${fullPath.source})?`, "g");
  return url;
}
export const urlFilterRegex = createUrlFilterRegex();
