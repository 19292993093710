<template>
  <div class="ChartContainer">
    <canvas ref="lineChart" id="myChart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';


export default {

  props: ['choosenPeriod', 'choosenId', 'data'],

  mounted() {
    this.renderChart();
  },

  methods: {
    renderChart() {
      const ctx = this.$refs.lineChart.getContext('2d');

      // Создаем график
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.choosenPeriod,
          datasets: [
            {
              label: 'USDT',
              data: this.data,
              fill: true,
              borderColor: '#CCADFF',
              backgroundColor: 'rgba(181, 155, 255, 0.5)',
              hoverBackgroundColor: '#5E5CE6',
              hoverBorderColor: "#fff",
              borderWidth: 2,
              tension: 0.1,
              cubicInterpolationMode: 'monotone'
            }
          ],
        },

        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 0.1,
                max: 1,
                fontColor: '#CBD5E0',
                fontSize: window.innerWidth > 680 ? 12 : 6,
                fontFamily: 'Gilroy-Medium'
              },

              gridLines: {
                borderDash: [4, 3],
                color: '#56577A',
                lineWidth: 1
              }

            }],

            xAxes: [{
              type: this.choosenId == 4 ? undefined : 'time',
              time: {
                unit: this.choosenId == 1 ? 'hour' : 'day',
                displayFormats: {
                  day: 'DD.MM',
                  hour: 'HH:00'
                }
              },
              ticks: {
                fontColor: '#CBD5E0',
                fontSize: window.innerWidth > 680 ? 13 : 6,
                fontFamily: 'Gilroy-Medium',
              },
              gridLines: {
                drawOnChartArea: false
              },

            }]

          },

          legend: {
            display: false
          }
        }
      });
    },

  }
};
</script>

<style scoped lang="scss">
.ChartContainer {
  height: 392px;
}

#myChart {
  height: 100% !important;
  width: 100% !important;
}

@media (min-width:1800px) {
  .ChartContainer {
    height: 436px;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  .ChartContainer {
    height: 341px;
  }
}

@media (max-width: 680px) {
  .ChartContainer {
    height: 241px;
  }
}
</style>
