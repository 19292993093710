<template>
  <header class="header container">
    <div class="header-left">
      <a href="#" class="header-logo"></a>
      <nav class="header-nav">
        <ul class="header-nav__list">
          <li class="header-nav__item">
            <span class="header-nav__item-icon"></span>
            <a href="#accordion-anchor" class="header-nav__item-link">
              {{ $t('header.Advantages') }}
            </a>
          </li>
          <li class="header-nav__item">
            <span class="header-nav__item-icon"></span>
            <a href="#blockchain" class="header-nav__item-link">
              {{ $t('header.Networks') }}
            </a>
          </li>
          <li class="header-nav__item">
            <span class="header-nav__item-icon"></span>
            <a href="#swiper" class="header-nav__item-link">
              {{ $t('header.Currency') }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="header-right">
      <div class="header-language">
        <span class="header-language__icon"></span>
        <div class="dropdown">
          <div class="header-language__choose">
           {{ $i18n.locale.toUpperCase() }}
          </div>
          <div class="dropdown-content">
            <switchLocale/>
          </div>
        </div>
        <span class="header-language__dot"></span>
      </div>
      <button class="header-button burger-button btn" @click="openLogIn">
        {{ userAreLoged == '' ? login : userAreLoged.slice(0, 6) }}
      </button>

      <button class="header-button__burger" @click="openBurger"></button>
    </div>
  </header>
</template>
<script>
import switchLocale from '@/components/landing/localeSwitcher.vue';

export default {
  props: {
    openBurger: Function,
    userAreLoged: String,
    openLogIn: Function,
  },

  computed: {
    login() {
      return this.$t('header.logIn')
    }
  },

  components: { switchLocale }
}
</script>
<style scoped lang="scss">
.header {
  padding-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left,
.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  flex: 1 1 42%;
}

.header-right {
  flex: 1 1 40%;
  padding-top: 18px;
}

.header-right {
  justify-content: flex-end;
}

.header-logo {
  height: 71px;
  width: 99px;
  background-image: url("../../../public/images/Logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.header-nav {
  padding-top: 18px;
}

.header-nav__list {
  display: flex;
  justify-content: space-between;
}

.header-nav__item {
  display: flex;
  position: relative;
}

.header-nav__item-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cpath d='M6.73867 4.12721L4.50687 1.87975C3.72517 1.09256 2.45339 1.08792 1.66597 1.86938C0.87413 2.65522 0.874128 3.93569 1.66596 4.72152L3.98608 7.02407C5.46801 8.49477 4.42652 11.0227 2.33869 11.0227H1.98864C0.890343 11.0227 0 11.9131 0 13.0114C0 14.1097 0.890342 15 1.98864 15H11.7528C12.5148 15 13.2483 14.71 13.8044 14.1888L14.1122 13.9003C14.6786 13.3694 15 12.6277 15 11.8513C15 11.1028 14.7012 10.3853 14.1699 9.858L13.2848 8.97957C12.09 7.79383 12.1044 5.85732 13.3168 4.68953L14.3407 3.70324C14.762 3.29745 15 2.7377 15 2.15277V2.06107C15 0.922773 14.0772 0 12.9389 0C11.8006 0 10.8779 0.922773 10.8779 2.06107V2.42118C10.8779 4.58231 8.26147 5.66069 6.73867 4.12721Z' fill='white'/%3E%3C/svg%3E");
}

.header-nav__item-link {
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.header-nav__item::before {
  position: absolute;
  display: flex;
  opacity: 0;
  content: "";
  height: 4px;
  left: 0;
  right: 0;
  bottom: -6px;
  background-color: #9747FF;
  border-radius: 20px;
}

.header-nav__item:hover::before {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.header-nav__item:not(:last-child) {
  margin-right: 55px;
}

.header-language {
  display: flex;
  align-items: center;
  margin-right: 98px;
  cursor: pointer;
  /* Dropdown Content (Hidden by Default) */
}

.header-language__icon {
  height: 18px;
  width: 33px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../../public/images/languageIcon.png");
}

.header-language .dropdown {
  position: relative;
  display: inline-block;
}

.header-language .dropdown-content {
  display: none;
  position: absolute;
  left: -5px;
  background-color: transparent;
  width: -moz-max-content;
  width: max-content;
  padding: 6px 7px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.12);
}

.header-language .dropdown:hover .dropdown-content {
  display: block;
}
.header-language__choose {
  width: 30px;
  color: #FFF;
  font-family: Gilroy-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-language__dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #FFF;
}

.header-language:hover .header-language__dot {
  background-color: #9747FF;
  transition: background-color 0.3s ease-in;
}

.header-button {
  padding: 3px 19px;
  height: 34px;
}

.header-button__burger {
  display: none;
}

@media (min-width: 1800px) {
  .header {
    padding-top: 28px;
  }

  .header-left {
    flex: 1 1 30%;
  }

  .header-right {
    padding-top: 0px;
  }

  .header-logo {
    height: 105px;
    width: 146px;
  }

  .header-nav {
    padding-top: 0;
  }

  .header-nav__item-link {
    font-size: 18px;
  }

  .header-language {
    margin-right: 116px;
  }

  .header-language__choose {
    font-size: 18px;
    margin-right: 4px;
  }

  .header-button {
    min-width: 135px;
    width: -moz-max-content;
    width: max-content;
    height: 35px;
    font-size: 18px;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  .header {
    padding-top: 14px;
  }

  .header-left {
    flex: 1 1 50%;
  }

  .header-right {
    flex: 1 1 27%;
    padding-top: 0;
  }

  .header-logo {
    width: 77px;
    height: 55px;
  }

  .header-nav {
    padding-top: 0;
  }

  .header-nav__item-link {
    font-size: 12px;
  }

  .header-nav__item:not(:last-child) {
    margin-right: 28px;
  }

  .header-language {
    margin-right: 35px;
  }

  .header-language__icon {
    width: 29px;
    margin-right: 7px;
  }

  .header-language__choose {
    width: 21px;
    font-size: 10px;
  }

  .header-button {
    font-size: 12px;
    height: 26px;
    padding: 3px 20px;
  }
}

@media (max-width: 680px) {
  .header {
    padding-top: 42px;
  }

  .header-left {
    padding-left: 5px;
  }

  .header-logo {
    width: 90px;
    height: 66px;
  }

  .header-nav {
    display: none;
  }

  .header-language {
    display: none;
  }

  .header-button {
    display: none;
  }

  .header-button__burger {
    display: flex;
    width: 16px;
    height: 30px !important;
    padding: 0 !important;
    background: none;
    border: none;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'%3E%3Cpath d='M1 11H9M1 6H15M7 1H15' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  }
}</style>