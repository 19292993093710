// Copyright 2023, Alexander Nekrasov, All rights reserved.

function require(expr, message) {
  if (expr !== true) throw `require failed: ${message}`;
}

function typecheck(v) {
  require(typeof v === "bigint", "must be bigint");
  require(v >= 0n, "must be 0n or positive");
}

export class SafeMath {
  static add(a, b) {
    typecheck(a);
    typecheck(b);
    const c = a + b;
    require(c >= a, "add overflow");
    return c;
  }

  static sub(a, b) {
    typecheck(a);
    typecheck(b);
    require(a >= b, "sub overflow");
    return a - b;
  }

  static mul(a, b) {
    typecheck(a);
    typecheck(b);
    if (a === 0n) return 0n;
    const c = a * b;
    require(c / a === b, "mul overflow");
    return c;
  }

  static div(a, b) {
    typecheck(a);
    typecheck(b);
    require(b > 0n, "division by zero");
    return a / b;
  }
}
