// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { sharedState } from "./sharedState";
import { WalletBase } from "./walletBase";

const handler = {
  get: function (target, prop /*, receiver*/) {
    //console.log("trap get", prop);
    if (sharedState.activeWallet instanceof WalletBase) {
      return sharedState.activeWallet[prop];
    }
    return undefined;
  },
  set(obj, prop, value) {
    //console.log("trap set", prop, value);
    if (sharedState.activeWallet instanceof WalletBase) {
      sharedState.activeWallet[prop] = value;
      return true;
    }
    return false;
  },
};

export const walletProxy = new Proxy({}, handler);
