// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Firebase } from "../firebase";
import { getFirebaseFirestore } from "../firebaseChunks";

export class NicknameCache {
  constructor(firebase) {
    if (!(firebase instanceof Firebase)) throw "InjectError";

    this.firebase = firebase;
    this.promises = {};
    this.cached = {};
  }

  resolveNickname(accountId, nickname) {
    this.cached[accountId] = nickname || "anonymous";
    const array = this.promises[accountId] || [];
    delete this.promises[accountId];
    array.forEach((resolve) => {
      resolve(nickname);
    });
  }

  getNickname(accountId) {
    if (!accountId) return Promise.resolve("anonymous");

    const cached = this.cached[accountId];
    if (cached) {
      console.log(`name from cache for ${accountId}`);
      return Promise.resolve(cached);
    }

    return new Promise((resolve) => {
      if (this.promises[accountId] instanceof Array) {
        console.log(`waiting name for ${accountId}`);
        this.promises[accountId].push(resolve);
        return;
      } else {
        console.log(`fetching name for ${accountId}`);
        this.promises[accountId] = [resolve];
        this.rawFetchNickname(accountId)
          .then((nickname) => {
            this.resolveNickname(accountId, nickname);
          })
          .catch((error) => {
            console.error(error);
            this.resolveNickname(accountId, "error");
          });
      }
    });
  }

  async rawFetchNickname(accountId) {
    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const publicRef = firestoreModule.doc(firestore, `/user/${accountId}/storage/public`);
    const publicSnapshot = await firestoreModule.getDoc(publicRef);
    return publicSnapshot.data()?.nickname;
  }
}
