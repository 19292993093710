<template>
  <div class="root" :class="classes">
    <div class="proplist">
      <span>name: {{wallet?.name}}</span>
      <span>detected: {{wallet?.detected}}</span>
      <span>connected: {{wallet?.isConnected}}</span>
      <span>attached: {{wallet?.isAttached}}</span>
      <span>active: {{wallet?.isActive}}</span>
    </div>
    <div class="center" v-if="wallet?.defaultAction">
      <ActivityButton :disabled="wallet?.isDisabled" :activity="wallet?.isPending" @click="Action">{{wallet?.defaultAction}}</ActivityButton>
    </div>
    <div class="toprightcontainer" v-if="wallet?.isDetachable">
      <button :disabled="wallet?.isDisabled || wallet?.isPending" @click="Detach">x</button>
    </div>
  </div>
</template>

<script>
import ActivityButton from './ActivityButton.vue';
import { WalletBase } from "../../code/walletBase";

export default {
  components: {
    ActivityButton,
  },
  props: {
    wallet: WalletBase,
  },
  computed: {
    classes() {
      const flags = [];
      if (this.wallet?.detected) flags.push("detected");
      if (this.wallet?.isConnected) flags.push("connected");
      if (this.wallet?.isAttached) flags.push("attached");
      if (this.wallet?.isActive) flags.push("active");
      return flags.join(" ");
    },
  },
  methods: {
    Action() {
      this.wallet?.Action();
    },
    Detach() {
      this.wallet?.Detach();
    }
  },
}
</script>

<style scoped lang="scss">
.root {
  min-width: 110px;
  position: relative;
  padding: 2px;
  font-size: 10px;
  border: 2px solid #777;
  min-height: 64px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.root.detected {
  opacity: 0.85;
  border-color: black;
}
.root.detected.active {
  opacity: 1;
  border-width: 2px;
  box-shadow: 0px 0px 3px 3px #7773;
}

.root.connected {
  border-color: #080;
}
.root.connected.attached {
  border-color: #c0f;
}

.center {
  display: flex;
  justify-content: center;
}

.proplist {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.toprightcontainer {
  position: absolute;
  top: 0;
  right: 0;
  button {
    background: white;
    border: none;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  button:hover {
    background: #eee;
  }
  button:active {
    background: #aaa;
  }
}

</style>
