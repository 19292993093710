// Copyright 2023, Alexander Nekrasov, All rights reserved.

/* global BigInt */
import { getAccount, getDonatrix } from "../global";
import { formatAccountId, formatFiatCurrency, formatAddress, urlFilterRegex } from "../format";

// export const MESSAGE_TYPE = Object.freeze({
//   Incoming: "Incoming",
//   Outgoing: "Outgoing",
// });

// export const MESSAGE_TYPES = [MESSAGE_TYPE.Incoming, MESSAGE_TYPE.Outgoing];

//const DEFAULT_FIAT = "usd";
//const FIAT = [DEFAULT_FIAT, "eur", "rub"];
const PREFIX = {
  usd: "$",
  eur: "€",
  rub: "₽",
};

export class Message {
  constructor() {
    this.id = undefined; // txHash
    this.fromAccountId = undefined;
    this.isCustomNickname = false;
    this.nickname = undefined;
    this.confirmedAt = new Date();
    this.replayAt = new Date();
    this.textProp = "";
    this.nativeValue = 0n;
    this.fiatValue = undefined; // map of values? usd -> 1.0, eur 0.9 etc.
    this.currencyContract = undefined;
    this.tokenData = undefined;

    this.isRemoving = false;
    this.isReplaying = false;
    this.status = undefined; // Fake/Confirmed
  }

  get date() {
    return new Date(this.confirmedAt).toLocaleString(undefined, {
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
  }

  get text() {
    const predicate = () => "<link>";
    const account = getAccount();
    return account.accountSettings.urlFilterSetting.value
      ? this.textProp.replace(urlFilterRegex, predicate)
      : this.textProp;
  }

  get token() {
    return this.tokenData;
  }

  get ticker() {
    return this.token?.name;
  }

  get uiValue() {
    const account = getAccount();
    if (this.fiatValue) {
      const name = this.ticker || formatAddress(this.currencyContract);
      const cur = account.accountSettings.displayCurrency.value;
      const prefix = PREFIX[cur];
      const value = formatFiatCurrency(this.fiatValue[cur]);
      return `${prefix}${value}/${name}`;
    } else {
      const name = this.ticker || formatAddress(this.currencyContract);
      const value = this.token?.decimals
        ? Number(this.rawValue) / Math.pow(10, this.token.decimals)
        : Number(this.rawValue) + "n";
      return `${value}/${name}`;
    }
  }

  fromJson(messageData) {
    this.id = messageData?.txHash;
    this.fromAccountId = messageData?.fromAccount;
    this.isCustomNickname = !!messageData?.nickname;
    this.nickname = messageData?.nickname || formatAccountId(this.fromAccountId);
    this.confirmedAt = new Date(messageData?.confirmedAt || 0);
    this.replayAt = new Date(messageData?.replayAt || 0);
    this.textProp = messageData?.message || "";
    this.nativeValue = BigInt(messageData?.rawValue);
    this.fiatValue = JSON.parse(JSON.stringify(messageData?.fiatValue || {}));
    this.currencyContract = messageData?.currencyContract;

    const donatrix = getDonatrix();
    this.tokenData = donatrix.settings.findToken(this.currencyContract);

    this.isRemoving = false;
    this.isReplaying = false;
    this.status = messageData?.status; // Fake/Confirmed
  }
}
