<template>
    <section id="steps">
        <div class="steps-overflow">
            <div class="container">
                <h2 class="steps-title">
                    <span class="steps-title__number">3</span>
                    <span class="steps-title__firstText">
                        {{ $t('steps.firstText') }}
                    </span>
                    <span class="steps-title__icon"></span>
                    <span class="steps-title__secondText">
                        {{ $t('steps.secondText') }}
                    </span>
                </h2>

                <ul class="steps-list">
                    <li class="steps-list__link steps-list__link-first">
                        <span class="steps-list__link-text">
                            {{ $t('steps.linkFirst') }}
                        </span>
                    </li>
                    <li class="steps-list__link steps-list__link-second">
                        <span class="steps-list__link-text">
                            {{ $t('steps.linkSecond') }}
                        </span>
                    </li>
                    <li class="steps-list__link steps-list__link-third">
                        <span class="steps-list__link-text">
                            {{ $t('steps.linkThird') }}
                        </span>
                    </li>
                </ul>

                <div class="steps-button btn" @click="openLogIn">
                    {{ $t('steps.button') }}
                </div>
            </div>
        </div>

        <div class="steps-overflow__transit"></div>
    </section>
</template>
<script>
export default {
    methods: {
        openLogIn() {
            this.$store.commit('openLogin')
        }
    }
}
</script>
<style scoped lang="scss">
#steps {
    background: url("../../../public/images/stepsBackground.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-size: cover;
}

#steps .steps-overflow {
    margin-top: -4px;
    background: linear-gradient(180deg, rgb(7, 4, 27), rgba(7, 4, 27, 0) 100%);
}

#steps .steps-title {
    margin: 0;
    margin-bottom: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#steps .steps-title__number {
    margin-right: 11px;
    color: #FFF;
    font-family: Zen Dots;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: lowercase;
}

#steps .steps-title__firstText {
    max-width: 117px;
    color: #FFF;
    font-family: Zen Dots;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: lowercase;
}

#steps .steps-title__icon {
    margin-left: 17px;
    margin-right: 21px;
    width: 15px;
    height: 96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='99' viewBox='0 0 16 99' fill='none'%3E%3Cpath d='M1 16V9L15 20V27L1 16Z' stroke='white'/%3E%3Cpath d='M1 27V20L15 31V38L1 27Z' stroke='white'/%3E%3Cpath d='M1 39V32L15 43V50L1 39Z' stroke='white'/%3E%3Cpath d='M1 50V43L15 54V61L1 50Z' stroke='white'/%3E%3Cpath d='M1 62V55L15 66V73L1 62Z' stroke='white'/%3E%3Cpath d='M1 74V67L15 78V85L1 74Z' stroke='white'/%3E%3Cpath d='M1 86V79L15 90V97L1 86Z' stroke='white'/%3E%3Cpath d='M15 1H1V5L15 16V1Z' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-title__secondText {
    color: #FFF;
    font-family: Zen Dots;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: lowercase;
}

#steps .steps-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 85px;
}

#steps .steps-list__link {
    height: 229px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#steps .steps-list__link-text {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
}

#steps .steps-list__link-first {
    position: relative;
    left: 2px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='363' height='231' viewBox='0 0 363 231' fill='none'%3E%3Cpath d='M12.1392 1H73.4051L87.3291 9.6962H300.07L314.291 1H331L349.101 12.1118V54.6266L335.177 62.8397V109.219L361.633 125.646V214.54L336.57 230H226.731L226.57 220.821L214.734 213.091H1V63.8059L11.443 57.0422V1' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-list__link-first .steps-list__link-text {
    max-width: 274px;
}

#steps .steps-list__link-first::after {
    position: absolute;
    display: block;
    width: 24px;
    height: 46px;
    content: "";
    top: 64px;
    right: -11px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='49' viewBox='0 0 31 49' fill='none'%3E%3Cpath d='M15.5004 26.7358L1.17969 14.1509V22.8302L15.5004 35.8491L29.8212 22.8302V14.1509L15.5004 26.7358Z' stroke='white'/%3E%3Cpath d='M15.5004 14.5849L1.17969 2V10.6792L15.5004 23.6981L29.8212 10.6792V2L15.5004 14.5849Z' stroke='white'/%3E%3Cpath d='M15.5004 38.8868L1.17969 26.3019V34.9811L15.5004 48L29.8212 34.9811V26.3019L15.5004 38.8868Z' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-list__link-second {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='362' height='231' viewBox='0 0 362 231' fill='none'%3E%3Cpath d='M33.0253 1L14.2278 12.1118L14.9241 54.6266L28.1519 63.3228V109.703L1 125.646V215.023L26.7595 230H136.063V220.821L149.291 213.091H360.937V62.3565L351.19 56.5591V1H290.62L276 9.6962H64.3544L49.038 1H33.0253Z' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-list__link-second .steps-list__link-text {
    max-width: 274px;
}

#steps .steps-list__link-third {
    position: relative;
    right: 2px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='361' height='231' viewBox='0 0 361 231' fill='none'%3E%3Cpath d='M328.037 1L346.798 12.1118L346.103 54.6266L332.901 63.3228V109.703L360 125.646V215.023L334.29 230H225.198V220.821L211.996 213.091H0.759491V62.3565L10.4875 56.5591V1H70.9399L85.5319 9.6962H296.768L312.055 1H328.037Z' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-list__link-third .steps-list__link-text {
    max-width: 266px;
}

#steps .steps-list__link-third::after {
    position: absolute;
    display: block;
    width: 14px;
    height: 47px;
    content: "";
    right: 2px;
    top: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='51' viewBox='0 0 16 51' fill='none'%3E%3Cpath d='M1.06934 10.7037V2L15.4304 15.0556V23.7593L1.06934 10.7037Z' stroke='white'/%3E%3Cpath d='M1.06934 23.7594V15.0557L15.4304 28.1112V36.8149L1.06934 23.7594Z' stroke='white'/%3E%3Cpath d='M1.06934 35.9444V27.2407L15.4304 40.2963V49L1.06934 35.9444Z' stroke='white'/%3E%3C/svg%3E");
}

#steps .steps-button {
    margin: 0 auto;
    width: 274px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#steps .steps-overflow__transit {
    height: 231px;
    background: linear-gradient(0deg, #07041B 0%, rgba(7, 4, 27, 0) 100%);
}

@media (min-width: 1800px) {
    #steps .steps-title__number {
        font-size: 90px;
    }

    #steps .steps-title__firstText {
        font-size: 35px;
    }

    #steps .steps-title__icon {
        margin-left: 30px;
        margin-right: 30px;
    }

    #steps .steps-title__secondText {
        font-size: 35px;
    }

    #steps .steps-list {
        margin-left: 41px;
        margin-right: 37px;
        margin-bottom: 103px;
    }

    #steps .steps-list__link {
        height: 338.358px;
    }

    #steps .steps-list__link-text {
        font-size: 25px;
    }

    #steps .steps-list__link-first::after {
        width: 46px;
        height: 72px;
        top: 94px;
        right: -20px;
    }

    #steps .steps-list__link-second .steps-list__link-text {
        max-width: 393px;
    }

    #steps .steps-list__link-third .steps-list__link-text {
        max-width: 393px;
    }

    #steps .steps-list__link-third::after {
        width: 21.219px;
        height: 69.446px;
        right: 8px;
        top: 102px;
    }

    #steps .steps-button {
        width: 367px;
        height: 52px;
        font-size: 20px;
    }

    #steps .steps-overflow__transit {
        height: 256px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    #steps .steps-title {
        margin: 0 0 25px;
    }

    #steps .steps-title__number {
        font-size: 60px;
    }

    #steps .steps-title__firstText {
        margin-right: 6px;
        max-width: 109px;
        font-size: 25px;
    }

    #steps .steps-title__icon {
        width: 14px;
        height: 84px;
        margin-left: 0;
        margin-right: 25px;
    }

    #steps .steps-title__secondText {
        font-size: 25px;
    }

    #steps .steps-list {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 49px;
    }

    #steps .steps-list__link {
        height: 137.4px;
    }

    #steps .steps-list__link-text {
        font-size: 12px;
    }

    #steps .steps-list__link-first .steps-list__link-text {
        max-width: 178.8px;
        padding-right: 6px;
    }

    #steps .steps-list__link-first::after {
        width: 17.185px;
        height: 27.601px;
        top: 39px;
        right: -8px;
    }

    #steps .steps-list__link-second .steps-list__link-text {
        max-width: 164.4px;
    }

    #steps .steps-list__link-third .steps-list__link-text {
        max-width: 159.6px;
    }

    #steps .steps-list__link-third::after {
        width: 8.617px;
        height: 28.2px;
        right: 3px;
        top: 40px;
    }

    #steps .steps-button {
        font-size: 12px;
        height: 35px;
        min-width: 133px;
        width: -moz-max-content;
        width: max-content;
    }

    #steps .steps-overflow__transit {
        height: 94px;
    }
}

@media (max-width: 680px) {
    #steps .steps-title {
        margin: 0 0 28px;
    }

    #steps .steps-title__number {
        margin-right: 4px;
        font-size: 40px;
    }

    #steps .steps-title__firstText {
        font-size: 15px;
        margin-right: 0;
        max-width: -moz-min-content;
        max-width: min-content;
    }

    #steps .steps-title__icon {
        margin-right: 0;
        margin-left: 0;
        width: 40px;
        height: 56.571px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='59' viewBox='0 0 13 59' fill='none'%3E%3Cpath d='M1 12.7852V7.28516L12 15.928V21.428L1 12.7852Z' stroke='white'/%3E%3Cpath d='M1 21.4277V15.9277L12 24.5706V30.0706L1 21.4277Z' stroke='white'/%3E%3Cpath d='M1 30.8574V25.3574L12 34.0003V39.5003L1 30.8574Z' stroke='white'/%3E%3Cpath d='M1 39.5V34L12 42.6429V48.1429L1 39.5Z' stroke='white'/%3E%3Cpath d='M1 48.9277V43.4277L12 52.0706V57.5706L1 48.9277Z' stroke='white'/%3E%3Cpath d='M12 1H1V4.14286L12 12.7857V1Z' stroke='white'/%3E%3C/svg%3E");
    }

    #steps .steps-title__secondText {
        font-size: 15px;
    }

    #steps .steps-list {
        margin-bottom: 54px;
    }

    #steps .steps-list__link {
        height: 74.355px;
    }

    #steps .steps-list__link-text {
        font-size: 10px;
    }

    #steps .steps-list__link-first {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='102' height='76' viewBox='0 0 102 76' fill='none'%3E%3Cpath d='M4.08392 1H21.0455L24.9004 3.82363H83.7982L87.7352 1H92.3611L97.3725 4.60797V18.4124L93.5176 21.0791V36.1384L100.842 41.472V70.3357L93.9031 75.3555H63.4941L63.4494 72.375L60.1727 69.8651H1V21.3928L3.89117 19.1967V1' stroke='white' stroke-width='0.5'/%3E%3C/svg%3E");
    }

    #steps .steps-list__link-first .steps-list__link-text {
        padding-right: 8px;
        max-width: 82.502px;
        text-align: center;
    }

    #steps .steps-list__link-first::after {
        width: 7.929px;
        height: 14.934px;
        top: 20px;
        right: -3px;
    }

    #steps .steps-list__link-second {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='76' viewBox='0 0 101 76' fill='none'%3E%3Cpath d='M9.80963 0.999771L4.60551 4.60774L4.79826 18.4121L8.46041 21.2358V36.2951L0.943359 41.4717V70.4923L8.07492 75.3552H38.3359V72.3747L41.998 69.8649H100.593V20.922L97.8941 19.0396V0.999771H81.1253L77.0776 3.8234H18.4832L14.2428 0.999771H9.80963Z' stroke='white' stroke-width='0.5'/%3E%3C/svg%3E");
    }

    #steps .steps-list__link-second .steps-list__link-text {
        max-width: 75.857px;
    }

    #steps .steps-list__link-third {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='101' height='76' viewBox='0 0 101 76' fill='none'%3E%3Cpath d='M91.1509 0.999771L96.3449 4.60774L96.1526 18.4121L92.4975 21.2358V36.2951L100 41.4717V70.4923L92.8822 75.3552H62.6798V72.3747L59.0247 69.8649H0.543594V20.922L3.2368 19.0396V0.999771H19.9732L24.013 3.8234H82.4941L86.7263 0.999771H91.1509Z' stroke='white' stroke-width='0.5'/%3E%3C/svg%3E");
    }

    #steps .steps-list__link-third .steps-list__link-text {
        max-width: 73.643px;
    }

    #steps .steps-list__link-third::after {
        width: 3.976px;
        height: 15.26px;
        right: 2px;
        top: 22px;
    }
}</style>