// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Firebase } from "./firebase";
import { NicknameCache } from "./classes/NicknameCache";
import { getFirebaseFirestore } from "./firebaseChunks";
import { Message } from "./classes/Message";

export const TYPE = {
  incoming: "incoming",
  outgoing: "outgoing",
};

export class Messages {
  constructor(firebase) {
    if (!(firebase instanceof Firebase)) throw "InjectError";

    this.firebase = firebase;
    this.nicknameCache = new NicknameCache(firebase);
    this.timestampForNewMessages = undefined;
    this.cancelIncomingSub = undefined;

    this.messages = [];
    this.latestIncoming = undefined;

    // these two fields must be separate for incoming/outgoing
    //this.startTimestamp = undefined;
    //this.endTimestamp = undefined;
  }

  Initialize() {
    this.timestampForNewMessages = new Date();
    this.messages = [];
  }

  FirebaseAccount(account) {
    if (!account) {
      this.StopListeners();
    }
  }

  StopListeners() {
    //console.log("stop");
    if (typeof this.cancelIncomingSub === "function") {
      this.cancelIncomingSub();
      this.cancelIncomingSub = undefined;
    }
    this.Initialize();
  }

  async UpdateNickname(message) {
    if (!(message instanceof Message)) return;
    if (message.isCustomNickname) return; // ignore custom names;

    const nickname = await this.nicknameCache.getNickname(message.fromAccountId);
    if (nickname) {
      message.nickname = nickname;
    }
  }

  // TODO
  // it is better to splic incoming/outgoing to separate functions
  // will be easier to work with different variables
  ProcessMessageEvent(changeType, doc) {
    const targetArray = this.messages;
    const messageData = doc.data();

    if (changeType === "added") {
      const message = new Message();
      message.fromJson(messageData);

      this.UpdateNickname(message);

      targetArray.push(message);
      this.HandleNewMessage(message);
    } else if (changeType === "modified") {
      const message = targetArray.find((message) => {
        return message.id === doc.id;
      });
      if (message) {
        message.fromJson(messageData);
        this.HandleNewMessage(message);
      }
    } else if (changeType === "removed") {
      const index = targetArray.findIndex((message) => {
        return message.id === doc.id;
      });
      if (index >= 0) {
        targetArray.splice(index, 1);
        //console.log(messageData);
      }
    }
  }

  HandleNewMessage(message) {
    if (!(message instanceof Message)) return;
    if (message.replayAt < this.timestampForNewMessages) return;

    this.latestIncoming = message;
    console.log("latest incoming:", this.latestIncoming.text);

    // const utter = new SpeechSynthesisUtterance(this.latestIncoming.message);
    // //utter.lang = "en-US";
    // speechSynthesis.speak(utter);
  }

  async StartListen() {
    if (!this.firebase.account) throw "no_auth";

    if (typeof this.cancelIncomingSub === "function") return;
    console.log(`start listen`);

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const account = this.firebase.account;

    //const utcNow = new Date();
    //utcNow.setHours(0, 0, 0, 0);
    //const timestamp = utcNow.toISOString(); // begin of today

    // prettier-ignore
    const collection = firestoreModule.collection(firestore, `/user/${account}/incoming`);
    //const query = firestoreModule.query(collection, firestoreModule.where("confirmedAt", ">=", timestamp));
    const q1 = firestoreModule.query(collection, firestoreModule.orderBy("confirmedAt", "desc"));
    const query = firestoreModule.query(q1, firestoreModule.limit(25));

    const cancelSub = firestoreModule.onSnapshot(query, (snapshot) => {
      let needResort = false;
      snapshot.docChanges().forEach((change) => {
        this.ProcessMessageEvent(change.type, change.doc);
        if (change.type === "added") {
          needResort = true;
        }
      });

      if (needResort) {
        const targetArray = this.messages;
        targetArray.sort((a, b) => {
          const t1 = new Date(a.confirmedAt);
          const t2 = new Date(b.confirmedAt);
          return t2 - t1;
        });
      }
    });

    this.cancelIncomingSub = cancelSub;
  }

  async removeInboxItem(txHash, confirmFunc) {
    //console.log(`try remove ${txHash}`);
    const message = this.messages.find((message) => {
      return message.id === txHash;
    });
    if (!message) return;
    //console.log(`removing ${txHash}`);
    message.isRemoving = true;

    if (typeof confirmFunc === "function") {
      try {
        const confirmed = await confirmFunc();
        if (!confirmed) throw false;
      } catch (ex) {
        message.isRemoving = false;
        return;
      }
    }

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const account = this.firebase.account;
    const ref = firestoreModule.doc(firestore, `/user/${account}/incoming/${txHash}`);

    // run transaction to prevent local caching
    await firestoreModule.runTransaction(firestore, async (transaction) => {
      transaction.delete(ref);
    });
  }

  async replayInboxItem(txHash) {
    const message = this.messages.find((message) => {
      return message.id === txHash;
    });
    if (!message) return;
    message.isReplaying = true;

    const firestoreModule = await getFirebaseFirestore();
    const firestore = firestoreModule.getFirestore(this.firebase.app);
    const account = this.firebase.account;
    const ref = firestoreModule.doc(firestore, `/user/${account}/incoming/${txHash}`);

    const value = {
      replayAt: new Date().toISOString(),
    };

    // run transaction to prevent local caching
    await firestoreModule.runTransaction(firestore, async (transaction) => {
      transaction.update(ref, value);
    });
  }
}
