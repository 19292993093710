// Copyright 2023, Alexander Nekrasov, All rights reserved.

import { Buffer } from "buffer";
import { initializeApp } from "firebase/app";
import { getFirebaseAuth, getFirebaseFunctions } from "./firebaseChunks";
import { decodeJWT } from "./jwt";
import { WalletBase } from "./walletBase";
import { sharedState } from "./sharedState";
import firebaseCfg from "./firebaseCfg.json";
import { WalletError } from "./walletError";
import { sleep } from "./time";

async function decryptCfg() {
  const key = Buffer.from(firebaseCfg[0], "base64");
  const cipher = Buffer.from(firebaseCfg[1], "base64");
  const iv = Buffer.from(firebaseCfg[2], "base64");
  const tag = Buffer.from(firebaseCfg[3], "base64");
  const encrypted = Buffer.concat([cipher, tag]);

  const importedKey = await window.crypto.subtle.importKey("raw", key, "AES-GCM", true, ["decrypt"]);
  const decodedBuffer = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv,
    },
    importedKey,
    encrypted
  );
  const plaintext = new TextDecoder("utf8").decode(decodedBuffer);
  return JSON.parse(plaintext);
}

export class Firebase {
  constructor() {
    this.sharedState = sharedState;
    this.sharedState.signInDelegate = this.SignInWithWallet.bind(this);
    this.sharedState.attachWalletDelegate = this.AttachWallet.bind(this);
    this.sharedState.detachWalletDelegate = this.DetachWallet.bind(this);
    this.sharedState.getSolanaLatestBlockhash = this.GetSolanaLatestBlockhash.bind(this);
    this.sharedState.httpCallableDelegate = this.CallFunction.bind(this);

    this.ResetError();
    this.region = undefined;
    this.app = undefined;

    this.initialized = false;
    this.authenticated = false;
    this.account = undefined;
    this.pendingSignIn = false;
    this.pendingDeleteAccount = false;
    this.readOnly = undefined;

    this._user = undefined;
    this.unsubAuthListener = undefined;
  }

  get account() {
    return this.sharedState.accountId;
  }

  set account(value) {
    this.sharedState.accountId = value;
  }

  ResetError(type, value) {
    this.errorType = type;
    this.errorValue = value;
  }

  WalletChanged() {
    this.ResetError();
  }

  async GetInitialUser() {
    if (!this.app) return undefined;

    let unsub = undefined;
    const user = await new Promise((resolve) => {
      getFirebaseAuth()
        .then((firebaseAuthModule) => {
          const auth = firebaseAuthModule.getAuth(this.app);
          if (!auth) return undefined;
          unsub = firebaseAuthModule.onAuthStateChanged(auth, (user) => {
            resolve(user);
          });
        })
        .catch((err) => {
          console.err("auth", err);
        });
    });
    unsub();
    return user;
  }

  async PostConstruct() {
    this.region = "europe-west1";
    const firebaseConfig = await decryptCfg();

    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    this._user = await this.GetInitialUser();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      await this.SignInWithToken(token);
    } else if (!this._user) {
      await this.SignOut();
    } else {
      await this.RecoverSignInStatus();
    }

    this.initialized = true;
    this.StartAuthListener();
  }

  async StartAuthListener() {
    if (!this.app) return;
    if (typeof this.unsubAuthListener === "function") return;

    const firebaseAuthModule = await getFirebaseAuth();
    const auth = firebaseAuthModule.getAuth(this.app);
    if (!auth) throw "failed to get auth wtf";

    //console.log("start auth listener");
    this.unsubAuthListener = firebaseAuthModule.onAuthStateChanged(auth, (user) => {
      const changed = this.account && user?.uid !== this.account;
      if (changed) {
        //console.log(`auth changed ${this.account}->${user?.uid}, signing out`);
        this.SignOut(true);
      }
    });
  }

  // unused for now
  StopAuthListener() {
    if (typeof this.unsubAuthListener === "function") {
      //console.log("stop auth listener");
      this.unsubAuthListener();
      this.unsubAuthListener = undefined;
    }
  }

  async SignOut(localOnly) {
    if (this.authenticated) {
      this.authenticated = false;
      this.readOnly = undefined;
      this.account = undefined;
      this._user = undefined;
      if (localOnly !== true) {
        const firebaseAuthModule = await getFirebaseAuth();
        const auth = firebaseAuthModule.getAuth(this.app);
        await firebaseAuthModule.signOut(auth);
      }

      this.sharedState.activeWallet?.Deactivate();
    }
  }

  async RecoverSignInStatus() {
    const firebaseAuthModule = await getFirebaseAuth();
    const idToken = await firebaseAuthModule.getIdToken(this._user);
    const decodedToken = decodeJWT(idToken);
    const account = decodedToken?.payload?.accountId;

    this.authenticated = !!account;
    this.readOnly = !this.authenticated || decodedToken?.payload?.tokenType === "Widgets";
    this.account = account;
  }

  async SignInWithToken(accessToken) {
    if (this.pendingSignIn) return;

    // try to recover current user
    if (this._user) {
      await this.RecoverSignInStatus();
    }

    try {
      this.ResetError();
      const loginResponse = await this.CallFunction("loginWithAccessToken", { accessToken });
      if (loginResponse.error || !loginResponse.token) {
        console.error(loginResponse);
        this.ResetError(loginResponse.error, loginResponse[loginResponse.error]);
        await this.SignOut();
        return;
      } else if (this.account === loginResponse.accountId) {
        // all good, stop at this point to not downgrade token type
        return;
      } else if (this.account) {
        // force signout
        await this.SignOut();
      }

      const firebaseAuthModule = await getFirebaseAuth();
      const auth = firebaseAuthModule.getAuth(this.app);

      this.pendingSignIn = true;
      await firebaseAuthModule.signInWithCustomToken(auth, loginResponse.token);

      this.authenticated = true;
      this.readOnly = loginResponse.tokenType === "Widgets";
      this.account = loginResponse.accountId;
    } catch (error) {
      this.SignOut(true);
      this.ResetError("genericTryLater");
    }
    this.pendingSignIn = false;
  }

  GenerateSignatureMessage(challenge, walletAddress) {
    // this template must match on front- and back-
    return `Click to sign-in.\n\nThis request will not trigger any on-chain activity or cost any fees.\n\nWallet address: ${walletAddress}\n\n${challenge}`;
  }

  GenerateAttachWalletMessage(challenge, walletAddress, accountId) {
    // this template must match on front- and back-
    return `Click to attach wallet address to account.\n\nThis request will not trigger any on-chain activity or cost any fees.\n\nWallet address: ${walletAddress}\nAccount: ${accountId}\n\n${challenge}`;
  }

  async SignIn() {
    return await this.SignInWithWallet(this.sharedState.activeWallet);
  }

  async SignInWithWallet(wallet) {
    if (this.account) return;
    if (this.sharedState.signingInWithUuid) return;

    console.log("SignInWithWallet", wallet?.name);

    //const wallet = this.sharedState.activeWallet;
    if (!(wallet instanceof WalletBase)) return;

    const walletAddress = wallet.address;
    if (!walletAddress) return;

    if (this.pendingSignIn) return;
    this.pendingSignIn = true;

    if (!walletAddress) {
      this.pendingSignIn = false;
      return;
    }

    this.sharedState.signingInWithUuid = wallet.uuid;
    try {
      this.ResetError();

      const timestamp = (new Date().getTime() / 1000).toFixed(0);
      const signature = await wallet.PersonalSign(this.GenerateSignatureMessage(timestamp, walletAddress));
      const timezoneOffset = new Date().getTimezoneOffset();
      const loginResponse = await this.CallFunction("login", { timestamp, signature, walletAddress, timezoneOffset });

      if (loginResponse.error || !loginResponse.token) {
        console.error(loginResponse);
        this.ResetError(loginResponse.error, loginResponse[loginResponse.error]);
        this.pendingSignIn = false;
        this.sharedState.signingInWithUuid = undefined;
        return;
      }

      const firebaseAuthModule = await getFirebaseAuth();
      const auth = firebaseAuthModule.getAuth(this.app);
      await firebaseAuthModule.signInWithCustomToken(auth, loginResponse.token);

      this.authenticated = true;
      this.readOnly = loginResponse.tokenType === "Widgets";
      this.account = loginResponse.accountId;
      this.pendingSignIn = false;
      this.sharedState.signingInWithUuid = undefined;
      wallet.Activate();
    } catch (error) {
      if (error instanceof WalletError) {
        this.ResetError("walletError", error.message);
      } else if (error.code === 4001) {
        // user rejected - do not pop any error
        this.ResetError();
      } else if (error.message === "cancelled") {
        // mobile TrustWallet
        this.ResetError();
      } else {
        this.ResetError("genericTryLater");
      }
      this.pendingSignIn = false;
      this.sharedState.signingInWithUuid = undefined;
    }
  }

  async AttachWallet(wallet) {
    if (this.account === undefined) return;
    if (!wallet || !wallet.address) return;
    if (this.sharedState.signingInWithUuid) return;

    const walletAddress = wallet.address;
    console.log("AttachWallet", wallet.address);
    const timestamp = (new Date().getTime() / 1000).toFixed(0);

    try {
      this.ResetError();
      this.sharedState.signingInWithUuid = wallet.uuid;
      const signature = await wallet.PersonalSign(
        this.GenerateAttachWalletMessage(timestamp, walletAddress, this.account)
      );
      const response = await this.CallFunction("attachWallet", { timestamp, signature, walletAddress });
      if (response.error) {
        this.ResetError(response.error, response[response.error]);
      }
      this.sharedState.signingInWithUuid = undefined;
      wallet.Activate();
    } catch (error) {
      this.ResetError("genericTryLater");
      this.sharedState.signingInWithUuid = undefined;
      throw error;
    }
  }

  async DetachWallet(wallet) {
    if (this.account === undefined) return;
    if (!wallet) return;
    if (this.sharedState.signingInWithUuid) return;

    const answer = window.confirm(`Are you sure to disable ${wallet.type}/${wallet.name} sign-in method?`);
    if (!answer) return; // rejected

    const signatureType = WalletBase.GetChainType(wallet.type);
    console.log("detaching", signatureType);
    try {
      this.ResetError();
      this.sharedState.signingInWithUuid = wallet.uuid;
      const response = await this.CallFunction("detachWallet", { signatureType });
      if (response.error) {
        this.ResetError(response.error, response[response.error]);
      }
      this.sharedState.signingInWithUuid = undefined;
    } catch (error) {
      this.ResetError("genericTryLater");
      this.sharedState.signingInWithUuid = undefined;
      throw error;
    }
  }

  async DeleteAccount() {
    if (this.account === undefined) return;
    const answer = window.confirm(`Are you sure to delete account?`);
    if (!answer) return; // rejected

    try {
      this.ResetError();
      this.pendingDeleteAccount = true;
      const response = await this.CallFunction("deleteAccount");
      if (response.error) {
        this.ResetError(response.error, response[response.error]);
      }
      this.pendingDeleteAccount = false;
      await this.SignOut();
    } catch (error) {
      this.pendingDeleteAccount = false;
      this.ResetError("genericTryLater");
      throw error;
    }
  }

  async GetSolanaLatestBlockhash(chainId) {
    if (this.account === undefined) throw "no auth";
    const response = await this.CallFunction("solana-latestBlockhash", { chainId });
    if (response.chainId !== chainId) throw "wtf";
    return response;
  }

  async CallFunction(name, data, maxRetry = 1, retryDelaySec = 5) {
    if (!name || typeof name !== "string") return;
    if (typeof maxRetry !== "number") throw "invalid input";
    if (typeof retryDelaySec !== "number" || retryDelaySec < 1) throw "invalid input";
    let attemptIndex = 0;

    const functionsModule = await getFirebaseFunctions();
    const functions = functionsModule.getFunctions(this.app, this.region);
    const callable = functionsModule.httpsCallable(functions, name);
    while (attemptIndex < maxRetry) {
      attemptIndex++;
      try {
        const result = await callable(data);
        return result.data;
      } catch (error) {
        if (attemptIndex >= maxRetry) {
          console.log(`[${attemptIndex}/${maxRetry}] call to ${name} failed`);
          throw error;
        } else if (!(error instanceof functionsModule.FirebaseError)) {
          throw error;
        } else if (error.code === "functions/invalid-argument") {
          // do not retry 400 Bad Request
          /* export declare type FunctionsErrorCodeCore = 'ok' | 'cancelled' | 'unknown' | 'invalid-argument' |
                                                          'deadline-exceeded' | 'not-found' | 'already-exists' |
                                                          'permission-denied' | 'resource-exhausted' | 'failed-precondition' |
                                                          'aborted' | 'out-of-range' | 'unimplemented' | 'internal' |
                                                          'unavailable' | 'data-loss' | 'unauthenticated';
          */
          throw error;
        } else {
          console.log("error.code:", error.code);
          console.log(`[${attemptIndex}/${maxRetry}] call to ${name} failed, retry in ${retryDelaySec} sec`);
          await sleep(retryDelaySec * 1000);
        }
      }
    }
  }
}
