function getDayInterval() {
    var startDate = new Date();
    startDate.setUTCHours(startDate.getUTCHours() - 23);
    startDate.setUTCMinutes(0);
    startDate.setUTCSeconds(0);
    startDate.setUTCMilliseconds(0);
    var endDate = new Date();
    endDate.setUTCHours(endDate.getUTCHours() + 1);
    endDate.setUTCMinutes(0);
    endDate.setUTCSeconds(0);
    endDate.setUTCMilliseconds(0);
    var d = (endDate.getTime() - startDate.getTime()) / 24;
    var date = new Date(startDate);
    var dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setUTCMilliseconds(date.getUTCMilliseconds() + d);
    }
    return { startDate: startDate, endDate: endDate, dates: dates };
}
function getWeekInterval(offset) {
    var startDate = new Date();
    startDate.setUTCDate(startDate.getUTCDate() - 7);
    startDate.setUTCHours(0);
    startDate.setUTCMinutes(offset);
    startDate.setUTCSeconds(0);
    startDate.setUTCMilliseconds(0);
    var endDate = new Date();
    endDate.setUTCDate(endDate.getUTCDate() + 1);
    endDate.setUTCHours(0);
    endDate.setUTCMinutes(0 + offset);
    endDate.setUTCSeconds(0);
    endDate.setUTCMilliseconds(0);
    var d = 24 * 3600 * 1000; // 1d
    var date = new Date(startDate);
    var dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setUTCMilliseconds(date.getUTCMilliseconds() + d);
    }
    return { startDate: startDate, endDate: endDate, dates: dates };
}
function getMonthInterval(offset) {
    var startDate = new Date();
    startDate.setUTCMonth(startDate.getUTCMonth() - 1);
    startDate.setUTCDate(startDate.getUTCDate() + 1);
    startDate.setUTCHours(0);
    startDate.setUTCMinutes(offset);
    startDate.setUTCSeconds(0);
    startDate.setUTCMilliseconds(0);
    var endDate = new Date();
    endDate.setUTCDate(endDate.getUTCDate() + 1);
    endDate.setUTCHours(0);
    endDate.setUTCMinutes(0 + offset);
    endDate.setUTCSeconds(0);
    endDate.setUTCMilliseconds(0);
    var d = 24 * 3600 * 1000; // 1d
    var date = new Date(startDate);
    var dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setUTCMilliseconds(date.getUTCMilliseconds() + d);
    }
    return { startDate: startDate, endDate: endDate, dates: dates };
}
function getYearInterval(offset) {
    var NUM_MONTHS = 12;
    var startDate = new Date();
    startDate.setUTCMonth(startDate.getUTCMonth() - NUM_MONTHS);
    startDate.setUTCDate(1);
    startDate.setUTCHours(0);
    startDate.setUTCMinutes(offset);
    startDate.setUTCSeconds(0);
    startDate.setUTCMilliseconds(0);
    var endDate = new Date();
    endDate.setUTCMonth(endDate.getUTCMonth() + 1);
    endDate.setUTCDate(1);
    endDate.setUTCHours(0);
    endDate.setUTCMinutes(offset);
    endDate.setUTCSeconds(0);
    endDate.setUTCMilliseconds(0);
    var dates = [];
    for (var i = 0; i < NUM_MONTHS + 2; ++i) {
        var date = new Date();
        date.setUTCMonth(date.getUTCMonth() - NUM_MONTHS + i);
        date.setUTCDate(1);
        date.setUTCHours(0);
        date.setUTCMinutes(offset);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        dates.push(date);
    }
    return { startDate: startDate, endDate: endDate, dates: dates };
}
export function getInterval(period, offset) {
    if (offset === void 0) { offset = 0; }
    if (period === "day")
        return getDayInterval();
    else if (period === "week")
        return getWeekInterval(offset);
    else if (period === "month")
        return getMonthInterval(offset);
    else if (period === "year")
        return getYearInterval(offset);
    else
        throw "unexpected period";
}
