// Copyright 2023, Alexander Nekrasov, All rights reserved.

export class WalletError {
  constructor(message) {
    this.message = message;
  }
}

export class WalletNotImplementedError extends WalletError {
  constructor() {
    super("not implemented");
  }
}

export class WalletUserRejectedError extends WalletError {
  constructor() {
    super(undefined);
  }
}

export class WalletValidationError extends WalletError {
  constructor(message) {
    super(message);
  }
}

export class WalletUnexpectedError extends WalletError {
  constructor(message) {
    super(message);
  }
}
