import Vue from "vue";
import App from "./App.vue";
import store from './store';
import router from './router';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';


// Import the functions you need from the SDKs you need

import wallet from "./plugins/walletPlugin";
Vue.use(wallet);

import firebase from "./plugins/firebasePlugin";
Vue.use(firebase);

import donatrix from "./plugins/donatrixPlugin";
Vue.use(donatrix);

import account from "./plugins/accountPlugin";
Vue.use(account);

import messages from "./plugins/messagesPlugin";
import i18n from './i18n'
Vue.use(messages);

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

 