// Copyright 2023, Alexander Nekrasov, All rights reserved.

function unbound() {
  console.log("unbound");
  return Promise.reject("unbound");
}

export const sharedState = {
  accountId: undefined,

  attachWalletDelegate: unbound,
  detachWalletDelegate: unbound,
  signInDelegate: unbound,
  signingInWithUuid: undefined,
  httpCallableDelegate: unbound,

  getSolanaLatestBlockhash: unbound,

  activeWalletUuid: undefined,
  activeWallet: undefined,

  publicInitialized: undefined,
  numWallets: 0,
  accountEvmAddress: undefined,
  accountSolanaAddress: undefined,
};
