<template>
    <section id="accordion">
        <div class="accordion-overflow">
            <div class="container">
                <div class="accordion-content">
                    <Planet :safe-focus="safeFocus" :anon-focus="anonFocus" :alerts-focus="alertsFocus"
                        :web-focus="webFocus" />

                    <Utp :choose-safe="chooseSafe" :choose-anon="chooseAnon" :choose-alerts="chooseAlerts"
                        :choose-web="chooseWeb" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import openAccordion from '@/mixins/openAccordion';
import Planet from '@/components/landing/planet.vue';
import Utp from '@/components/landing/utp.vue';

export default {
    components: { Planet, Utp },

    data: () => ({
        safeFocus: true,
        anonFocus: false,
        alertsFocus: false,
        webFocus: false,
    }),

    mixins: [openAccordion],

    methods: {
        chooseSafe() {
            if (!this.safeFocus) {
                this.safeFocus = true,
                    this.anonFocus = false,
                    this.alertsFocus = false,
                    this.webFocus = false
            }
        },

        chooseAnon() {
            if (!this.anonFocus) {
                this.safeFocus = false,
                    this.anonFocus = true,
                    this.alertsFocus = false,
                    this.webFocus = false
            }
        },

        chooseAlerts() {
            if (!this.alertsFocus) {
                this.safeFocus = false,
                    this.anonFocus = false,
                    this.alertsFocus = true,
                    this.webFocus = false
            }
        },

        chooseWeb() {
            if (!this.webFocus) {
                this.safeFocus = false,
                    this.anonFocus = false,
                    this.alertsFocus = false,
                    this.webFocus = true
            }
        },
    },

}
</script>

<style scoped lang="scss">
#accordion {
    margin-top: -1px;
    height: -moz-max-content;
    height: max-content;
    background: #07041B;
}

#accordion .accordion-overflow {
    position: relative;
    top: -222px;
    height: -moz-max-content;
    height: max-content;
}

#accordion .accordion-content {
    display: flex;
    justify-content: space-between;
    height: 962px;
}

@media (min-width: 1800px) {
    #accordion .accordion-overflow {
        top: -128px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    #accordion .accordion-overflow {
        top: 0;
    }

    #accordion .accordion-content {
        justify-content: flex-start;
        flex-direction: column;
        min-height: 1240.34px;
        height: -moz-max-content;
        height: max-content;
        overflow: hidden;
    }
}

@media (max-width: 680px) {
    #accordion .accordion-content {
        min-height: 900px;
    }
}
</style>