<template>
    <section id="blockchain">
        <div class="container">
            <div class="blockchain-content">
                <div class="blockchain-content__left">
                    <h2 class="blockchain-title">
                        {{ $t('blockchain.Networks') }}
                    </h2>

                    <BlockchainNetworks />
                </div>

                <div class="blockchain-content__right">
                    <h2 class="blockchain-title">
                        {{ $t('blockchain.Wallets') }}
                    </h2>

                    <SupportedWallets/>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
import BlockchainNetworks from '@/components/landing/blockchainNetworks.vue';
import SupportedWallets from '@/components/landing/supportedWallets.vue'

export default {
    components: { BlockchainNetworks, SupportedWallets }
}
</script>
<style scoped lang="scss"> 
#blockchain {
     margin-top: -139px;
     background: #07041B;
 }

 #blockchain .blockchain-content {
     position: relative;
     display: flex;
     justify-content: space-between;
     z-index: 1;
     margin-left: -30px;
 }

 #blockchain .blockchain-content .blockchain-title {
     margin-top: 0;
     margin-bottom: 100px;
     color: #FFF;
     text-align: center;
     font-family: Zen Dots;
     font-size: 30px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
 }

 @media (min-width: 1800px) {
     #blockchain {
         margin-top: -1px;
         padding-top: 103px;
     }

     #blockchain .blockchain-content .blockchain-title {
         font-size: 35px;
     }
 }

 @media (min-width: 0px) and (max-width: 1290px) {
     #blockchain {
         margin-top: -31px;
     }

     #blockchain .blockchain-content {
         margin-left: -21px;
         flex-direction: column;
     }

     #blockchain .blockchain-content__right {
         position: absolute;
         top: 530px;
         width: 100%;
     }

     #blockchain .blockchain-content .blockchain-title {
         margin-left: 20px;
         margin-bottom: 41px;
         font-size: 25px;
     }

 }

 @media (max-width: 680px) {
     #blockchain {
         margin-top: 0px;
     }

     #blockchain .blockchain-content__left {
         padding-left: 16px;
         padding-right: 4px;
     }

     #blockchain .blockchain-content__right {
         top: 521px;
         left: 27%;
         width: -moz-max-content;
         width: max-content;
     }

     #blockchain .blockchain-content__right .blockchain-title {
         margin-left: 0px !important;
         margin-bottom: 39px;
     }

     #blockchain .blockchain-content .blockchain-title {
         font-size: 15px;
         margin-left: 9px;
     }
 }
 </style>