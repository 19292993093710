<template>
    <li class="blockchain-item">
        <span class="blockchain-icon" :class="supportedWallet.name">
            <img src="../../../public/images/blockchainBorder.svg" width="70" height="90" alt="border" class="svg">
        </span>
        {{ supportedWallet.name }}
    </li>
</template>
<script>
export default {
 props: ["supportedWallet"]
}
</script>
<style scoped lang="scss">
.blockchain-item {
    color: #FFF;
    text-align: center;
    font-family: Zen Dots;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 153px;
}

.blockchain-icon {
    display: block;
    width: 68.5px;
    height: 88px;
    margin: 0 auto;
    margin-bottom: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-size: auto;
}

.blockchain-item .Metamask {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='51' viewBox='0 0 52 51' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.9908 14.5072L49.3387 0L29.994 14.5105L29.9908 14.5072ZM36.6692 43.5242L48.1974 46.7412L51.5 35.3696L47.9374 24.2408L50.2793 21.4687L49.2586 20.7199L50.8797 19.2227L49.6388 18.2514L51.2601 16.9969L50.1992 16.1672L51.9006 7.79023L49.3387 0L32.866 6.23219L19.1352 6.23216L2.66257 0L0.100586 7.79023L1.82192 16.1672L0.721072 16.9969L2.36233 18.2514L1.12138 19.2227L2.74262 20.7199L1.72183 21.4687L4.06365 24.2408L0.52075 35.3696L3.80327 46.7412L15.3122 43.524L22.2175 40.145L23.1783 39.457H28.8228L29.7835 40.145L29.7847 40.1456L30.345 44.6774L29.8247 44.2322H22.1788L21.6784 44.6774L22.2187 40.1451L15.3135 43.5241L17.5552 45.3856L22.0987 48.5624H29.8846L34.4482 45.3856L36.6692 43.5242ZM36.6692 43.5242L36.6685 43.524L29.7847 40.1456L29.7846 40.1451L36.6696 43.5239L36.6692 43.5242ZM20.156 28.8743L16.1132 30.7561L21.8379 32.4559L20.156 28.8743ZM31.8462 28.8743L35.9096 30.7561L30.1651 32.456L31.8462 28.8743Z' fill='url(%23paint0_linear_483_7155)'/%3E%3Cpath d='M21.8377 32.456L16.1133 30.7564L20.1564 28.8745L21.8377 32.456Z' fill='%23120423'/%3E%3Cpath d='M30.165 32.456L31.8464 28.8745L35.9095 30.7564L30.165 32.456Z' fill='%23120423'/%3E%3Cpath d='M29.784 40.1449L28.8232 39.457H23.1788L22.2182 40.1449L21.6777 44.6775L22.1782 44.2323H29.824L30.3445 44.6775L29.784 40.1449Z' fill='%23120423'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_483_7155' x1='0.624574' y1='27.8769' x2='42.6934' y2='27.8769' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234776E6'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.blockchain-item .Phantom {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='66' height='46' viewBox='0 0 66 46' fill='none'%3E%3Cpath d='M56.2958 22.2582H50.396C50.396 10.1575 40.6151 0.348511 28.5492 0.348511C16.6325 0.348511 6.94406 9.9174 6.70698 21.8108C6.46165 34.1047 17.9627 44.7803 30.223 44.7803H31.7651C42.5741 44.7803 57.0615 36.2947 59.3251 25.9556C59.7432 24.0496 58.2417 22.2582 56.2958 22.2582ZM19.7817 22.7972C19.7817 24.4154 18.462 25.7389 16.8485 25.7389C15.235 25.7389 13.9153 24.4148 13.9153 22.7972V18.0383C13.9153 16.4201 15.235 15.0967 16.8485 15.0967C18.462 15.0967 19.7817 16.4201 19.7817 18.0383V22.7972ZM29.9671 22.7972C29.9671 24.4154 28.6475 25.7389 27.0339 25.7389C25.4204 25.7389 24.1007 24.4148 24.1007 22.7972V18.0383C24.1007 16.4201 25.421 15.0967 27.0339 15.0967C28.6475 15.0967 29.9671 16.4201 29.9671 18.0383V22.7972Z' fill='url(%23paint0_linear_483_7456)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_483_7456' x1='7.23614' y1='25.8542' x2='50.0296' y2='25.8542' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%234776E6'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

@media (min-width: 1800px) {
    .blockchain-item {
        font-size: 20px;
    }

    .blockchain-item .svg {
        width: 81.594px;
        height: 104.822px;
    }

    .blockchain-icon {
        width: 81.594px;
        height: 104.822px;
        margin-bottom: 44px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {
    .blockchain-item {
        min-width: 139px;
    }
}

@media (max-width: 680px) {
    .blockchain-item {
        font-size: 12px;
        min-width: -moz-max-content;
        min-width: max-content;
        max-width: 110px;
    }

    .blockchain-icon {
        width: 49.429px;
        height: 63.499px;
        background-size: 40px;
        margin: 0 auto 15px;
    }

    .blockchain-icon .svg {
        width: 49.429px;
        height: 63.499px;
    }
}
</style>