import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/pages/MainPage'
import aboutSection from '@/pages/MainPage'
import acContainer from '@/pages/MainPage'
import accordionAnchor from '@/pages/MainPage'
import blockchain from '@/pages/MainPage'
import swiper from '@/pages/MainPage'
import NotFoundPage from '@/pages/NotFoundPage'
import DashBoardPage from '@/pages/DashboardPage'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: MainPage,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashBoardPage,
  },
  {
    path: '/about',
    name: 'aboutSection',
    component: aboutSection
  },
  {
    path: '/ac-container',
    name: 'ac-container',
    component: acContainer
  },
  {
    path: '/accordion-anchor',
    name: 'accordion-anchor',
    component: accordionAnchor
  },
  {
    path: '/blockchain',
    name: 'blockchain',
    component: blockchain
  },
  {
    path: '/swiper',
    name: 'swiper',
    component: swiper
  },
  {
    path: "*",
    name: "notFound",
    component: NotFoundPage,
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  routes,
});

export default router;
