// Copyright 2023, Alexander Nekrasov, All rights reserved.
var SettingBase = /** @class */ (function () {
    function SettingBase(domain, name, type) {
        this.domain = domain;
        this.name = name;
        this.type = type;
        this._uninitialized = true;
    }
    Object.defineProperty(SettingBase.prototype, "displayName", {
        get: function () {
            return "".concat(this.domain, "Setting_").concat(this.name);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SettingBase.prototype, "uninitialized", {
        get: function () {
            return this._uninitialized;
        },
        enumerable: false,
        configurable: true
    });
    SettingBase.prototype.MarkInitialized = function () {
        this._uninitialized = false;
    };
    return SettingBase;
}());
export { SettingBase };
