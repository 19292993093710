<template>
  <div class="limit-control-root">
    <span>{{ limit.ticker }}</span>
    <div>
      <input
        ref="input"
        class="input"
        :placeholder="limit.placeholder"
        v-model="value"
        v-on:keyup.esc="blur"
        v-on:keyup.enter="blur"
      />
      <button class="btn" :disabled="!limit.pendingValue" @click="revert">x</button>
    </div>
  </div>
</template>

<script>
import { DonationLimit } from "../../code/classes/LimitsHelper";

export default {
  props: {
    limit: { type: DonationLimit },
  },
  data() {
    return {
      valid: "",
    };
  },
  computed: {
    value: {
      get() {
        return this.limit.value;
      },
      set(value) {
        this.limit.SetValue(value);
      },
    },
  },
  methods: {
    revert() {
      this.limit.Revert();
      this.blur();
    },
    blur() {
      this.$refs.input.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.limit-control-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  .btn {
    margin-left: 4px;
  }
  min-width: 120px;
}
.input {
  min-width: 42px;
  width: 42px;
}
</style>
