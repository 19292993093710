<template>
  <div class="testbed-message" :class="classes">
    <div class="message" :class="classes">
      <button @click="remove" v-if="!$firebase.readOnly">X</button>
      <button @click="replay" v-if="!$firebase.readOnly" :disabled="message.isReplaying">replay</button>
      [{{ message.date }}] [{{ message.uiValue }}] [{{ message.nickname }}]: {{ message?.text }}
    </div>
  </div>
</template>

<script>
import { Message } from "../../code/classes/Message";

export default {
  props: {
    message: { type: Message, default: undefined },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.message.isRemoving) classes.push("removing");
      if (this.message.status === "Fake") classes.push("fake");
      return classes.join(" ");
    },
  },
  methods: {
    remove() {
      const id = this.message?.id;
      if (!id) return;

      this.$messages.removeInboxItem(id, () => {
        return new Promise((resolve) => {
          setTimeout(() => {
            const answer = window.confirm(`Are you sure to remove '${this.message.text}'?`);
            resolve(answer);
          }, 100);
        });
      });
    },
    replay() {
      const id = this.message.id;
      this.$messages.replayInboxItem(id);
    },
  },
};
</script>

<style scoped lang="scss">
.testbed-message {
  .removing {
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }

  .message {
    padding: 2px 2px;
    border: 1px solid black;
    overflow-wrap: anywhere;
  }

  .fake {
    background-image: repeating-linear-gradient(-45deg, #f702, #f702 8px, #0000 8px, #0000 22px);
  }
}
</style>
