// Copyright 2023, Alexander Nekrasov, All rights reserved.
var FiatValue = /** @class */ (function () {
    function FiatValue(value) {
        this.usd = Number(value === null || value === void 0 ? void 0 : value.usd) || 0;
        this.eur = Number(value === null || value === void 0 ? void 0 : value.eur) || 0;
        this.rub = Number(value === null || value === void 0 ? void 0 : value.rub) || 0;
    }
    return FiatValue;
}());
export { FiatValue };
var MessageSummaryItem = /** @class */ (function () {
    function MessageSummaryItem(id, startDate, endDate, value) {
        this.id = id;
        this.numTransactions = (value === null || value === void 0 ? void 0 : value.numTransactions) || 0;
        this.fiatValue = new FiatValue(value === null || value === void 0 ? void 0 : value.fiatValue);
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
    }
    return MessageSummaryItem;
}());
export { MessageSummaryItem };
