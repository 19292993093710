// Copyright 2023, Alexander Nekrasov, All rights reserved.

/* global BigInt */
import { GetEthers } from "./ethersChunk";
import { LocalStorage } from "./classes/LocalStorage";
import { sleep } from "./time";
import { WalletBase, WalletType } from "./walletBase";
import { WalletUserRejectedError, WalletValidationError, WalletUnexpectedError } from "./walletError";
import { erc20GetBalance, erc20GetAllowance } from "./erc20";
import { createApproveTxData } from "./erc20";
import { createDonateTxData, createDonateTokenTxData } from "./donatrixApi";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const WalletMode = Object.freeze({
  MetaMask: "MetaMask",
  Phantom: "Phantom",
  PhantomAsk: "PhantomAsk",
});

export class WalletMetamask extends WalletBase {
  constructor() {
    super("MetaMask", WalletType.Ethereum);
    this.walletMode = undefined;
    this.provider = undefined;

    // overriding base class stuff
    this.zeroAddress = ZERO_ADDRESS;
    this.redirectToHomePageImpl = this.RedirectToHomePageImpl.bind(this);
    this.connectImpl = this.ConnectImpl.bind(this);
    this.personalSignImpl = this.PersonalSignImpl.bind(this);
    this.sendTxImpl = this.SendTxDataImpl.bind(this);
    this.sendApproveTxImpl = this.SendApproveTxImpl.bind(this);
    this.sendDonateTxImpl = this.SendDonateTxImpl.bind(this);
    this.changeNetworkImpl = this.ChangeNetworkImpl.bind(this);
    this.getAttached = () => {
      return (
        this.sharedState.publicInitialized === true &&
        this.address !== undefined &&
        this.address === this.sharedState.accountEvmAddress
      );
    };
    this.getAttachable = () => {
      return (
        this.sharedState.publicInitialized === true &&
        this.address !== undefined &&
        this.sharedState.accountEvmAddress === undefined
      );
    };
    this.getDetachable = () => {
      return this.sharedState.publicInitialized === true && this.sharedState.accountEvmAddress !== undefined;
    };

    this.getBalanceImpl = this.GetBalanceImpl.bind(this);
    this.getTokenBalanceImpl = this.GetTokenBalanceImpl.bind(this);
    this.getTokenAllowanceImpl = this.GetTokenAllowanceImpl.bind(this);
  }

  get destinationAddressField() {
    return "evmAddress";
  }

  async PostConstruct() {
    const provider = window.ethereum;
    if (!provider) {
      return;
    }
    // TODO think how to make it nice, this constraint prevent mobile TrustWallet to be detected
    // if (!provider.isMetaMask) {
    //   console.log("There is a provider, however it is not MetaMask compatible. Opting to MetaMask download flow");
    //   return;
    // }

    const phantomInstalled = window.phantom?.ethereum?.isPhantom === true;
    const forcePhantom = phantomInstalled && provider?.isPhantom === true;
    const askMode = phantomInstalled && provider.detected !== undefined;

    // figure out wallet mode
    if (phantomInstalled && askMode) this.walletMode = WalletMode.PhantomAsk;
    else if (forcePhantom) this.walletMode = WalletMode.Phantom;
    else this.walletMode = WalletMode.MetaMask;
    console.log(`wallet.mode=${this.walletMode}`);

    // only for Phantom, wait a bit, let Phantom to finish inject
    // otherwise reqiest/events won't work
    if (this.walletMode !== WalletMode.MetaMask) {
      await sleep(100);
    }

    provider.on("chainChanged", (chainId) => {
      this.chainId = Number(chainId);
      console.log(`${this.name} chainId`, this.chainId);
    });

    provider.on("accountsChanged", (accounts) => {
      this.OnAccountsChanged(accounts);
      const autoConnect = !!accounts[0];
      const walletSettings = new LocalStorage("walletSettings");
      walletSettings.set("autoConnect", autoConnect);
    });

    this.Initialize(provider);
  }

  SetWalletMode() {
    this.walletMode = this.provider.isPhantom ? WalletMode.Phantom : WalletMode.MetaMask;
  }

  async Initialize(provider) {
    this.provider = provider;
    this.detected = true;

    const walletSettings = new LocalStorage("walletSettings");
    const autoConnect = walletSettings.get("autoConnect", false);

    if (autoConnect) {
      this.isPendingConnect = true;

      this.FetchChainId();
      this.provider
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          this.OnAccountsChanged(accounts);
          this.isPendingConnect = false;

          const autoConnect = !!accounts[0];
          walletSettings.set("autoConnect", autoConnect);

          this.SetWalletMode();
        })
        .catch(() => {
          this.OnAccountsChanged([]);
          this.isPendingConnect = false;
          walletSettings.set("autoConnect", false);
        });
    }
  }

  OnAccountsChanged(accounts) {
    this.address = accounts[0];
  }

  FetchChainId() {
    this.provider.request({ method: "eth_chainId" }).then((chainId) => {
      this.chainId = Number(chainId);
      console.log(`${this.name} chainId`, this.chainId);
    });
  }

  async ChangeNetworkImpl(chainId) {
    if (this.isPendingNetwork) return;
    if (this.sharedState.signingInWithUuid) return;
    this.isPendingNetwork = chainId;

    try {
      await this.provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
          },
        ],
      });
    } catch (err) {
      console.error("change network failed/rejected");
    }
    this.isPendingNetwork = undefined;
  }

  RedirectToHomePageImpl() {
    window.open("https://metamask.io/download/", "_blank");
  }

  ConnectImpl() {
    if (this.isPendingConnect) return;
    if (!this.provider) {
      console.error("wallet::Connect: no provider");
      return;
    }

    this.isPendingConnect = true;

    const walletSettings = new LocalStorage("walletSettings");
    this.provider
      .request({ method: "eth_requestAccounts" })
      .then((accounts) => {
        this.OnAccountsChanged(accounts);
        this.isPendingConnect = false;
        walletSettings.set("autoConnect", true);

        if (!this.chainId) this.FetchChainId();
        this.SetWalletMode();
        this.Activate();
      })
      .catch((err) => {
        walletSettings.set("autoConnect", false);
        this.isPendingConnect = false;
        if (err.code !== 4001) {
          console.error(err);
        }
      });
  }

  async PersonalSignImpl(challenge) {
    if (!this.address || typeof challenge !== "string") return;
    this.isPendingSign = true;
    try {
      const signature = await this.provider.request({
        method: "personal_sign",
        params: [challenge, this.address],
      });
      this.isPendingSign = false;
      return signature;
    } catch (error) {
      this.isPendingSign = false;
      throw error;
    }
  }

  async GetBalanceImpl() {
    if (!this.address) return 0n;
    const balance = await this.provider.request({
      method: "eth_getBalance",
      params: [this.address, "latest"],
    });
    return balance;
  }

  async GetTokenBalanceImpl(tokenAddress) {
    //console.log(this.address, tokenAddress);
    if (this.address && tokenAddress && tokenAddress !== ZERO_ADDRESS) {
      const balance = await erc20GetBalance(this.provider, tokenAddress, this.address);
      return BigInt(balance);
    } else {
      return 0n;
    }
  }

  async GetTokenAllowanceImpl(tokenAddress, allowanceFor) {
    //console.log(this.address, tokenAddress, allowanceFor);
    if (
      this.address &&
      tokenAddress &&
      tokenAddress !== ZERO_ADDRESS &&
      allowanceFor &&
      allowanceFor !== ZERO_ADDRESS
    ) {
      const allowance = await erc20GetAllowance(this.provider, tokenAddress, this.address, allowanceFor);
      return BigInt(allowance);
    } else {
      return 0n;
    }
  }

  async SendTxDataImpl(txData, onTxHash) {
    if (!this.address) return; //error
    if (this.isPendingTx) return;
    this.isPendingTx = true;

    const ethers = await GetEthers();
    const provider = new ethers.providers.Web3Provider(this.provider, "any");
    const signer = provider.getSigner();
    try {
      const tx = await signer.sendTransaction(txData);
      if (typeof onTxHash === "function") {
        onTxHash(tx.hash);
      }

      const receipt = await tx.wait();
      //console.log(receipt);
      this.isPendingTx = false;
      return receipt;
    } catch (error) {
      this.isPendingTx = false;
      if (error?.code === 4001 || error?.code === "ACTION_REJECTED") {
        throw new WalletUserRejectedError();
      } else {
        throw new WalletUnexpectedError(error);
      }
    }
  }

  async SendApproveTxImpl(tokenAddress, spenderAddress, rawAmount) {
    const data = await createApproveTxData(spenderAddress, rawAmount);
    const txData = {
      to: tokenAddress,
      data,
    };
    return await this.SendTxDataImpl(txData);
  }

  async SendDonateTxImpl(
    recipientAddress,
    tokenAddress,
    rawAmount,
    feeUnits,
    params = { splitterAddress: undefined },
    txHashCallback
  ) {
    if (!recipientAddress) throw new WalletValidationError("recipientAddress is required");
    if (!tokenAddress) throw new WalletValidationError("tokenAddress is required");
    if (!rawAmount) throw new WalletValidationError("rawAmount is required");
    if (typeof feeUnits !== "bigint" || feeUnits > 10000) throw new WalletValidationError("invalid feeUnits");
    if (!params.splitterAddress) throw new WalletValidationError("params.splitterAddress is required");

    const txData = {
      to: params.splitterAddress,
    };

    const ethers = await GetEthers();
    if (tokenAddress === ZERO_ADDRESS) {
      const data = await createDonateTxData(recipientAddress, feeUnits);
      txData["data"] = data;
      txData["value"] = ethers.utils.parseUnits(rawAmount.toString(), "wei").toHexString();
    } else {
      const value = ethers.utils.parseUnits(rawAmount.toString(), "wei").toHexString();
      const data = await createDonateTokenTxData(recipientAddress, value, tokenAddress, feeUnits);
      txData["data"] = data;
    }

    return this.SendTxDataImpl(txData, txHashCallback);
  }
}
