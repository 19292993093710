import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        logIn: 'none',
        userIsLoged: false,
    },

    mutations: {
        openLogin(state) {
          state.logIn = 'flex'
        },

        closeLogin(state) {
            state.logIn = 'none'
        },
    }
})